import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import DrawRequestModal from "../DrawRequestModal";
import { PrivateLabelFees } from "typedef/PrivateLabelFees";
import { FC, useEffect, useState } from "react";
import CButton from "components/CButton";
import { HELOCFormProps } from "typedef/HELOCFormProps";
import API from "utils/API";
import WarningIcon from "@mui/icons-material/Warning";

const PERCENTAGE = 100;

const Draw: FC<HELOCFormProps> = ({
  newValuesSlider,
  loading,
  loan,
  initialValuesSliderHELOC,
  marks,
  recalculateOffer,
  drawLessThan100,
  setDrawLessThan100,
}) => {
  const theme = useTheme();
  const [editDraw, setEditDraw] = useState(false);
  const [fees, setFees] = useState<PrivateLabelFees>();
  useEffect(() => {
    API.get<PrivateLabelFees>(`/get/origination-fees`).then((response) => {
      if (!("error" in response)) {
        setFees(response.data);
      }
    });
  }, []);
  //@ts-ignore
  let drawPercentaje;

  if (
    newValuesSlider?.initialDrawAmount &&
    loan?.finalOffer?.initialDrawAmount
  ) {
    drawPercentaje =
      (newValuesSlider?.initialDrawAmount / loan?.finalOffer?.amount) *
      PERCENTAGE;
  }

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 2fr"
      alignItems="center"
      sx={{ backgroundColor: theme.palette.background.paper, p: 2 }}
    >
      <Box>
        <Typography variant="h6" fontSize={20}>
          Draw:
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        {loading || !newValuesSlider?.initialDrawAmount ? (
          <Skeleton width={"75%"} height={"1.4rem"} />
        ) : (
          <>
            <Typography
              variant="h5"
              display="flex"
              alignItems="center"
              color={theme.palette.text.primary}
              fontFamily={"Pro Display Bold"}
              sx={{
                color: drawLessThan100 ? theme.palette.warning.main : "black",
              }}
            >
              {drawLessThan100 && (
                <WarningIcon
                  style={{ fontSize: 25, marginRight: 10 }}
                  htmlColor={theme.palette.warning.main}
                />
              )}
              $
              {Number(newValuesSlider.initialDrawAmount).toLocaleString(
                "en-US",
              )}
              {drawLessThan100 && ` (${drawPercentaje?.toFixed(0)}% draw)`}
            </Typography>
            <CButton
              sx={{ minHeight: 0, minWidth: 0, padding: 0, ml: 2 }}
              onClick={() => setEditDraw(true)}
            >
              Edit
            </CButton>
          </>
        )}
      </Box>

      {editDraw && (
        <DrawRequestModal
          loan={loan}
          fees={fees}
          showSlider={initialValuesSliderHELOC?.showSlider}
          openModal={editDraw}
          onClose={() => {
            setEditDraw(false);
          }}
          initialValuesSliderHELOC={initialValuesSliderHELOC}
          marks={marks}
          loading={loading}
          recalculateOffer={recalculateOffer}
          setDrawLessThan100={setDrawLessThan100}
          drawLessThan100={drawLessThan100}
          newValuesSlider={newValuesSlider}
        />
      )}
    </Box>
  );
};

export default Draw;
