import CLoader from "components/CLoader";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { PrivateLabel } from "typedef/PrivateLabel";
import API from "utils/API";
import checkIpOrLocalhost from "utils/checkIpOrLocalhost";

interface PrivateLabelContextType {
  privateLabel: PrivateLabel | undefined;
}

export const PrivateLabelContext = createContext<
  PrivateLabelContextType | undefined
>(undefined);

export const UsePrivateLabelContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [isLoading, setLoading] = useState(true);
  const [privateLabel, setPrivateLabel] = useState<PrivateLabel>();

  const fetchData = async (hostname: string) => {
    try {
      const response = await API.get<PrivateLabel>({
        url: `/get-unsec/private-label/${hostname}`,
      });
      if ("error" in response) {
        alert(response.error);
      } else {
        const privateLabel = response.data;
        setPrivateLabel(privateLabel);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    //const hostname = window.location.hostname;
    //used for test privateLabel
    const hostname = checkIpOrLocalhost(window.location.hostname)
      ? (process.env.REACT_APP_PRIVATE_LABEL_DOMAIN as string)
      : window.location.hostname;
    fetchData(hostname);
  }, []);

  useEffect(() => {
    const includeBorrowerPortalWidget =
      privateLabel?.zendesk?.includeBorrowerPortalWidget;
    const borrowerPortalWidgetUrl =
      privateLabel?.zendesk?.borrowerPortalWidgetUrl;
    const currentHostname = window.location.hostname;
    const isLocalhost = checkIpOrLocalhost(currentHostname);

    if (
      !isLocalhost &&
      includeBorrowerPortalWidget &&
      borrowerPortalWidgetUrl
    ) {
      if (!document.getElementById("ze-snippet")) {
        const scriptElement = document.createElement("script");
        scriptElement.id = "ze-snippet";
        scriptElement.src = borrowerPortalWidgetUrl;
        document.head.appendChild(scriptElement);
      }
    }
  }, [
    privateLabel?.zendesk?.borrowerPortalWidgetUrl,
    privateLabel?.zendesk?.includeBorrowerPortalWidget,
  ]);

  const values = useMemo(() => ({ privateLabel }), [privateLabel]);

  if (isLoading) return <CLoader loading={isLoading} />;

  if (privateLabel && privateLabel.favicon) {
    const link: HTMLLinkElement = document.createElement("link");
    link.rel = "icon";
    link.href = privateLabel.favicon;
    document.getElementsByTagName("head")[0].appendChild(link);
  }

  if (privateLabel?.lenderName) {
    document.title = `${privateLabel.lenderName} - Home Equity Loans`;
  }

  return (
    <PrivateLabelContext.Provider value={values}>
      {children}
    </PrivateLabelContext.Provider>
  );
};

export function usePrivateLabel() {
  const context = useContext(PrivateLabelContext);
  if (!context) {
    throw new Error("PrivateLabelContextProvider not found");
  }
  return context;
}
