import API from "utils/API";
import Loan from "typedef/Loan";
import { useCallback, useEffect, useState } from "react";

const useLoan = () => {
  const [loan, setLoan] = useState<Loan>();
  const [loading, setLoading] = useState(false);

  const getAndSetCoborrowerLoan = useCallback(async () => {
    setLoading(true);
    try {
      const response = await API.get<Loan>(`/get/my-loan`);
      if ("error" in response) {
        console.error(response.error);
        alert(response.error);
      } else {
        setLoan(response.data);
      }
    } catch (error) {
      console.error(error);
      alert(error);
    }
    setLoading(false);
  }, []);

  const refetchLoan = useCallback(async () => {
    await getAndSetCoborrowerLoan();
  }, [getAndSetCoborrowerLoan]);

  useEffect(() => {
    getAndSetCoborrowerLoan();
  }, [getAndSetCoborrowerLoan]);

  return { loan, refetchLoan, loading };
};

export default useLoan;
