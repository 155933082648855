import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme, Skeleton } from "@mui/material";
import CButton from "components/CButton";
import CDialog from "components/CDialog";
import dollarFormatter from "utils/FormatterDollar";
import API from "utils/API";
import { PrivateLabelFees } from "typedef/PrivateLabelFees";
import Loan, { LoanFees } from "typedef/Loan";
import { ValuesHELOC } from "screens/TypeOfCreditLine/useDefineLoanType";

interface ShowDetailsModalProps {
  openModal: boolean;
  onClose?: (event?: React.SyntheticEvent, reason?: string) => void;
  loanFees: LoanFees | undefined;
  loan: Loan | undefined;
  newValuesSlider: ValuesHELOC | undefined;
}

const AMOUNT_OF_DECIMALS = 2;
const PERCENTAGE = 100;

const ShowDetailsModal: React.FC<ShowDetailsModalProps> = ({
  openModal,
  onClose,
  loanFees,
  loan,
  newValuesSlider,
}) => {
  const theme = useTheme();
  const [fees, setFees] = useState<PrivateLabelFees>();
  const [loading, setLoading] = useState(false);

  const state = loan?.property.address.components.state_abbreviation;
  useEffect(() => {
    API.get<PrivateLabelFees>(
      `/getServiceUnsecured/origination-fees?state=${state}`,
    )
      .then((response) => {
        if (!("error" in response)) {
          setFees(response.data);
        }
      })
      // eslint-disable-next-line
      .catch((_error) => {});
  }, [state]);
  const originationFee =
    fees?.totalFee && newValuesSlider?.initialDrawAmount
      ? fees?.totalFee * newValuesSlider?.initialDrawAmount
      : null;
  const taxes =
    loan?.borrowerSteps?.titleVerification.recordingFeesAndOtherTaxes;
  const totalFee = fees?.totalFee
    ? (fees?.totalFee * PERCENTAGE).toFixed(AMOUNT_OF_DECIMALS)
    : null;

  const stateIsVA = state === "VA" ? true : false;

  const creditReport = !stateIsVA
    ? Number(loanFees?.feesByLoanStateType?.creditReport ?? 0)
    : 0;
  const complianceDocPrepeNote = !stateIsVA
    ? Number(loanFees?.feesByLoanStateType?.complianceDocPrepeNote ?? 0)
    : 0;
  const AVMInspection = !stateIsVA
    ? Number(loanFees?.feesByLoanStateType?.AVMInspection ?? 0)
    : 0;
  const idVerificationFee = !stateIsVA
    ? Number(loanFees?.idVerficationFee ?? 0)
    : 0;
  const eRecordingFee = !stateIsVA ? Number(loanFees?.eRecordingFee ?? 0) : 0;
  const legalVestingFee = !stateIsVA
    ? Number(loanFees?.legalVestingFee ?? 0)
    : 0;
  const taxesValue = !stateIsVA ? Number(taxes ?? 0) : 0;

  const total =
    Number(originationFee ?? 0) +
    creditReport +
    complianceDocPrepeNote +
    AVMInspection +
    idVerificationFee +
    eRecordingFee +
    legalVestingFee +
    taxesValue;

  const details = [
    ["Credit Report", creditReport],
    ["Compliance / Doc Prep / Enote", complianceDocPrepeNote],
    ["Property AVM / Inspection", AVMInspection],
    ["ID Verification", idVerificationFee],
    ["eRecording", eRecordingFee],
    ["Legal and Vesting", legalVestingFee],
    ["Taxes", taxesValue],
  ];

  useEffect(() => {
    setLoading(!(loanFees && fees && originationFee));
  }, [loanFees, fees, originationFee]);

  return (
    <CDialog
      maxWidth="lg"
      open={openModal}
      PaperProps={{
        sx: {
          width: { xs: "95vw", sm: "320px" },
          maxWidth: "1200px",
          margin: "0 auto",
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          textAlign: "center",
          width: "0 auto",
        }}
      >
        <Typography
          variant="h4"
          fontFamily="Pro Display Bold"
          width="100%"
          textAlign={"left"}
        >
          Estimated Fees
        </Typography>
        <Box
          sx={{
            justifyContent: "justify-between",
            display: "flex",
            gap: "60px",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box
            style={{
              justifyContent: "justify-between",
              display: "flex",
              flexDirection: "column",
              flex: "1 1 0",
            }}
          >
            <Box
              sx={{
                display: "flex",
                textAlign: "left",
                textWrap: "nowrap",
                margin: "20px 0 15px 0",
              }}
            >
              <Typography
                fontSize={18}
                color={theme.palette.text.primary}
                fontFamily="Pro Display Bold"
                sx={{ flex: "1 1 0", textAlign: "left", textWrap: "nowrap" }}
              >
                {`Origination Fee (${totalFee ?? 0}%)`}:
              </Typography>
              <Typography
                fontSize={14}
                color={theme.palette.text.primary}
                fontFamily="Pro Display Bold"
                sx={{ flex: "1 1 0", textAlign: "right" }}
              >
                {!loading ? (
                  dollarFormatter.format(Number(originationFee))
                ) : (
                  <Skeleton
                    width="75%"
                    height={"1.5rem"}
                    sx={{ flex: "1 1 0", textAlign: "right" }}
                  />
                )}
              </Typography>
            </Box>
            <Typography
              fontSize={18}
              color={theme.palette.text.primary}
              fontFamily="Pro Display Bold"
              sx={{ flex: "1 1 0", textAlign: "left", textWrap: "nowrap" }}
            >
              Fees:
            </Typography>
            {details.map(([title, value]) => (
              <Box
                key={title}
                display="flex"
                justifyContent="space-between"
                alignItems={"flex-start"}
                sx={{ flex: "1 1 0" }}
              >
                <Typography
                  fontSize={18}
                  color={theme.palette.text.primary}
                  sx={{ flex: "1 1 0", textAlign: "left", textWrap: "nowrap" }}
                >
                  {title}:
                </Typography>
                <Typography
                  fontSize={14}
                  color={theme.palette.text.primary}
                  fontFamily="Pro Display Regular"
                  ml={2}
                  sx={{ flex: "1 1 0", textAlign: "right" }}
                >
                  {!loading ? (
                    typeof value === "number" ? (
                      dollarFormatter.format(Number(value))
                    ) : (
                      String(value)
                    )
                  ) : (
                    <Skeleton
                      width="75%"
                      height={"1.5rem"}
                      sx={{ flex: "1 1 0", textAlign: "right" }}
                    />
                  )}
                </Typography>
              </Box>
            ))}
            <Box
              sx={{
                display: "flex",
                textAlign: "left",
                textWrap: "nowrap",
                marginTop: "20px",
              }}
            >
              <Typography
                fontSize={18}
                color={theme.palette.text.primary}
                fontFamily="Pro Display Bold"
                sx={{ flex: "1 1 0", textAlign: "left", textWrap: "nowrap" }}
              >
                Total:
              </Typography>
              <Typography
                fontSize={14}
                color={theme.palette.text.primary}
                fontFamily="Pro Display Bold"
                sx={{ flex: "1 1 0", textAlign: "right" }}
              >
                {!loading ? (
                  dollarFormatter.format(Number(total))
                ) : (
                  <Skeleton
                    width="75%"
                    height={"1.5rem"}
                    sx={{ flex: "1 1 0", textAlign: "right" }}
                  />
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
        <CButton
          type="reset"
          variant="outlined"
          fullWidth
          sx={{
            marginTop: 2,
            backgroundColor: theme.palette.primary.main,
            color: "white",
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
          onClick={onClose}
        >
          Close Details
        </CButton>
      </Box>
    </CDialog>
  );
};

export default ShowDetailsModal;
