import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { HELOCFormProps } from "typedef/HELOCFormProps";

const Property: FC<HELOCFormProps> = ({ loan }) => {
  const theme = useTheme();
  const property = loan?.property;

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 2fr"
      alignItems="center"
      sx={{ backgroundColor: theme.palette.background.paper, p: 2 }}
    >
      <Typography variant="h6">Property</Typography>
      <Box display="flex" alignItems="center">
        {property ? (
          <Typography
            variant="h5"
            color={theme.palette.text.primary}
            fontFamily={"Pro Display Bold"}
          >
            {property?.address?.delivery_line_1}.{" "}
            {property?.address?.components?.city_name},{" "}
            {property?.address?.components?.state_abbreviation}.{" "}
            {property?.address?.components?.zipcode}
          </Typography>
        ) : (
          <Skeleton width="75%" height={"1.5rem"} />
        )}
      </Box>
    </Box>
  );
};

export default Property;
