import { Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import CButton from "components/CButton";
import { HELOCOfferType } from "./HELOCOffer";

const ConfirmButton = ({
  thereIsOffer,
  archiveLoan,
  offer,
}: {
  thereIsOffer: boolean;
  archiveLoan: () => void;
  offer: HELOCOfferType;
}) => {
  const theme = useTheme();

  const offerMessage = {
    message: `After you proceed, your offer is confirmed and cannot be changed. We will then complete your title review and once its clear we can schedule closing.`,
    buttomMessage: "Confirm Offer & Proceed",
  };
  const noOfferMessage = {
    message: `We will be mailing you an official notice with the reasons for the denial. As things often change with time, we invite you to reapply in the future. Thank you for your time and consideration.`,
    buttomMessage: "Confirm",
  };
  const disabledOptions = thereIsOffer
    ? !offer?.newValuesSlider || offer.loading
    : offer.loading;

  return (
    <Box
      marginTop={1}
      textAlign={"center"}
      bgcolor={theme.palette.background.paper}
      padding={10}
      sx={{
        borderRadius: thereIsOffer ? "0 0 34px 34px" : "34px",
      }}
    >
      <Box margin="0 auto" maxWidth="650px">
        <Typography
          color={theme.palette.text.primary}
          fontSize={{ xs: "1rem", md: "1.4rem" }}
          fontWeight={550}
          textAlign="left"
        >
          {thereIsOffer ? offerMessage.message : noOfferMessage.message}
        </Typography>
        {!thereIsOffer && (
          <Typography
            color={theme.palette.text.primary}
            fontSize={{ xs: "1rem", md: "1.4rem" }}
            fontWeight={550}
            textAlign="left"
            mt={5}
          >
            We hope you have a nice day.
          </Typography>
        )}
      </Box>
      <Box mt={4}>
        <CButton
          fullWidth
          type="submit"
          variant="contained"
          loading={offer.isAccepting}
          onClick={() =>
            thereIsOffer
              ? offer.saveOffer(
                  offer.newValuesSlider?.initialDrawAmount as number,
                )
              : archiveLoan()
          }
          disabled={disabledOptions}
          name="acceptAndCompleteHELOCApplication"
        >
          {thereIsOffer
            ? offerMessage.buttomMessage
            : noOfferMessage.buttomMessage}
        </CButton>
      </Box>
    </Box>
  );
};

export default ConfirmButton;
