import CButton from "components/CButton";
import CDialog from "components/CDialog";
import API from "utils/API";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export type ConfirmTXNotHomesteadProps = {
  open: boolean;
  onAccept: () => void;
};

const ConfirmTXNotHomestead = ({
  open,
  onAccept,
}: ConfirmTXNotHomesteadProps) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const navigate = useNavigate();
  const handleAccept = async (confirmation: boolean) => {
    setConfirmLoading(true);
    await API.post({
      url: "/save-to-loan/confirm-tx-not-homestead",
      method: "POST",
      data: { confirm: confirmation },
    });
    setConfirmLoading(false);
    onAccept();
  };

  return (
    <CDialog
      open={open}
      icon="warning"
      maxWidth="xs"
      title={`We've noticed your property is not a homestead`}
      description={`According to our data this property does not have a Homestead exemption. We are unable to lend if this is the case. If you know this to be incorrect, please select "Confirm" below and we will verify as part of the origination process.`}
    >
      <CButton
        fullWidth
        loading={confirmLoading}
        onClick={() => {
          handleAccept(true);
        }}
        variant="contained"
        sx={{ marginBottom: 3, marginTop: 1 }}
        name="txNotHomesteadDialog-confirm"
      >
        Confirm
      </CButton>
      <CButton
        type="reset"
        variant="outlined"
        fullWidth
        sx={{ marginTop: 2 }}
        onClick={() => {
          handleAccept(false);
          navigate("/login", { replace: true });
        }}
        name="txNotHomesteadDialog-cancel"
      >
        Cancel
      </CButton>
    </CDialog>
  );
};

export default ConfirmTXNotHomestead;
