import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import CDialog from "components/CDialog";
import CButton from "components/CButton";
import { UseFormReturn } from "react-hook-form";
import { RegisterFormDialogs, RegisterFormInputs } from "./useRegisterForm";

export interface Props {
  form: UseFormReturn<RegisterFormInputs, unknown> & {
    loading: boolean;
    errorMessage: string | undefined;
    openedDialog: RegisterFormDialogs | undefined;
    setOpenedDialog: React.Dispatch<
      React.SetStateAction<RegisterFormDialogs | undefined>
    >;
    onSaveUnqualified: (error?: {
      id: string;
      message: string;
    }) => Promise<void>;
  };
}

const ConfirmValuesDialog: React.FC<Props> = ({ form }) => {
  const theme = useTheme();
  const formWatcher = form.watch();

  return (
    <CDialog
      maxWidth="xs"
      disableEscapeKeyDown={form.loading}
      onClose={form.loading ? () => null : undefined}
      open={form.openedDialog === "MAX_OFFER_ERROR"}
    >
      <form>
        <Typography
          component={"div"}
          variant="h4"
          marginBottom={3}
          textAlign={"left"}
        >
          <p style={{ margin: 0 }}>
            {formWatcher["legalFirstNameBorrower"]}{" "}
            {formWatcher["legalLastNameBorrower"]},
          </p>
          <p style={{ margin: 0 }}>
            we're just making sure if these are the correct values you have
            added.
          </p>
        </Typography>
        <Typography
          textAlign={"left"}
          component={"div"}
          sx={{
            fontSize: {
              xs: "1rem",
              md: "1.4rem",
              fontFamily: "Pro Display Semibold",
            },
          }}
        >
          <p>
            Home Value:{" "}
            <span style={{ color: theme.palette.primary.main }}>
              {formWatcher["estimatedHomeValue"]}
            </span>
          </p>
          <p>
            Current Balance:{" "}
            <span style={{ color: theme.palette.primary.main }}>
              {formWatcher["currentLoanBalance"]}
            </span>
          </p>
          <p>
            Requested Loan Amount:{" "}
            <span style={{ color: theme.palette.primary.main }}>
              {formWatcher["requestedLoanAmount"]}
            </span>
          </p>
          <p>
            Credit Score:{" "}
            <span style={{ color: theme.palette.primary.main }}>
              {formWatcher["creditScoreRange"]}
            </span>
          </p>
          <p>
            Annual Income:{" "}
            <span style={{ color: theme.palette.primary.main }}>
              {formWatcher["totalAnnualIncomeBorrower"]}
            </span>
          </p>
        </Typography>

        <Typography
          my={"1rem"}
          color={theme.palette.text.secondary}
          variant="subtitle1"
          textAlign={"justify"}
        >
          If you made a mistake, please close this modal and enter the correct
          values to continue.
        </Typography>
        <Stack
          justifyContent="space-between"
          marginTop={7}
          direction={
            useMediaQuery(theme.breakpoints.only("xs")) ? "column" : "row"
          }
          gap={2}
          width={"100%"}
        >
          <CButton
            style={{
              width: useMediaQuery(theme.breakpoints.only("xs"))
                ? "100%"
                : "auto",
            }}
            variant="contained"
            disabled={form.loading}
            onClick={() => form.setOpenedDialog(undefined)}
          >
            Go back
          </CButton>
          <CButton
            style={{
              width: useMediaQuery(theme.breakpoints.only("xs"))
                ? "100%"
                : "auto",
            }}
            variant="contained"
            disabled={form.loading}
            onClick={() =>
              form.onSaveUnqualified(
                form.openedDialog
                  ? {
                      id: form.openedDialog ?? "",
                      message: form.errorMessage ?? "",
                    }
                  : undefined,
              )
            }
          >
            Confirm values
          </CButton>
        </Stack>
      </form>
    </CDialog>
  );
};

export default ConfirmValuesDialog;
