import { useMemo, Fragment } from "react";
import { Timeline } from "@mui/lab";
import LoanInformation from "./LoanInformation";
import Typography from "@mui/material/Typography";
import QuestionsBox from "components/CQuestionChat";
import BrokerDisclosuresVerification from "./Steps/BrokerDisclosures";
import IdVerification from "./Steps/IdVerification";
import OfferInformation from "./Steps/OfferInformation";
import TitleVerification from "./Steps/TitleVerification";
import CreditVerification from "./Steps/CreditVerification";
import IncomeVerification from "./Steps/IncomeVerification";
import NPEAnnouncement from "./StepsDialogs/NPEAnnouncement";
import FundingVerification from "./Steps/FundingVerification";
import HomeValueVerification from "./Steps/HomeValueVerification";
import FinalOfferVerification from "./Steps/FinalOfferVerification";
import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import DemographicDataVerification from "./Steps/DemographicsVerification";
import ScheduleClosingVerification from "./Steps/ScheduleClosingVerification";
import InitialDisclosuresVerification from "./Steps/InitialDisclosuresVerification";
import ClosingDisclosuresVerification from "./Steps/ClosingDisclosuresVerification";
import AnnuncementCoborrowerPending from "./StepsDialogs/AnnuncementCoborrowerPending";
import AnnuncementCreateNewOfferPending from "./StepsDialogs/AnnuncementCreateNewOfferPending";
import InsuranceVerification from "./Steps/verifyInsurance";
import useTracker from "./useTrackerContext";
import { TrackerStatusColor } from "typedef/TrackerStatusColor";
import UploadDocumentB2 from "./StepsDialogs/UploadDocumentsB2";
import useMissingDocs from "utils/useMissingDocs";
import ConsentForHardpull from "./StepsDialogs/ConsentForHardpull";
import JointAccountAnnouncement from "./StepsDialogs/JointAccountAnnouncement/index";

const CTracker: React.FC<{
  isBorrower: boolean;
}> = ({ isBorrower }) => {
  const theme = useTheme();
  const { loan, coborrowerInviteIsOpen } = useTracker();

  const showAddCoborrower = useMemo(() => {
    if (
      (loan?.coborrowerSteps && loan?.coborrowerId) ||
      loan?.property?.dateAddressMatched
    )
      return false;
    else return true;
  }, [loan]);

  const showAddJACoborrower = useMemo(() => {
    if (
      loan?.borrowerSteps?.incomeVerification?.isJointAccount
        ?.isJointAccount === false &&
      !loan?.coborrowerId &&
      loan?.borrowerSteps?.incomeVerification?.incomeSelection === "plaidCRA"
    ) {
      return true;
    } else return false;
  }, [loan]);

  const showDocUploadModal = useMemo(() => {
    if (loan?.coborrowerFlags?.showedDocUploadModal) return false;
    else return true;
  }, [loan]);

  const borrowerWithoutHardpull = useMemo(() => {
    if (
      (loan?.borrowerFlags?.boConsentedHardpull !== undefined &&
        loan?.borrowerFlags?.boConsentedHardpull !== true &&
        loan?.borrowerSteps?.idVerification?.status === "success" &&
        loan?.borrowerSteps?.creditVerification?.status !== "failed") ||
      (!loan?.borrowerSteps?.hardpullVerification?.hardCreditScore &&
        loan?.borrowerSteps?.idVerification?.status === "success" &&
        loan?.borrowerSteps?.creditVerification?.status === "success")
    )
      return true;
    else return false;
  }, [loan]);

  const coborrowerWithoutHardpull = useMemo(() => {
    if (
      (loan?.coborrowerFlags?.boConsentedHardpull !== undefined &&
        loan?.coborrowerFlags?.boConsentedHardpull !== true &&
        loan?.coborrowerSteps?.idVerification?.status === "success" &&
        loan?.coborrowerSteps?.creditVerification?.status !== "failed") ||
      (!loan?.coborrowerSteps?.hardpullVerification?.hardCreditScore &&
        loan?.coborrowerSteps?.idVerification?.status === "success" &&
        loan?.coborrowerSteps?.creditVerification?.status !== "failed")
    )
      return true;
    else return false;
  }, [loan]);

  const docsToUpload = useMissingDocs(loan);

  const trackerStatusColor: TrackerStatusColor = {
    failed: theme.palette.error.main,
    pending: theme.palette.text.disabled,
    success: theme.palette.primary.main,
    analyzing: theme.palette.warning.main,
  };

  const hasLoanOfficer = loan?.loanOfficerId && loan?.brokerId;

  return (
    <Container
      maxWidth="md"
      disableGutters={useMediaQuery(theme.breakpoints.only("xs"))}
    >
      {
        <Fragment>
          <Box maxWidth="md">
            <Typography
              mt={4}
              mb={5}
              variant="h1"
              lineHeight={1}
              color={theme.palette.text.primary}
              letterSpacing={-0.8}
              px={{ xs: 2, md: 0 }}
              fontSize={{ xs: "2.5rem", md: "4.2rem" }}
            >
              Track Your Loan Application
            </Typography>
            <Box
              px={{ xs: 2, md: 6 }}
              py={{ xs: 4, md: 5 }}
              bgcolor={theme.palette.background.paper}
              sx={{
                scrollbarWidth: "none",
                borderTopLeftRadius: 33,
                borderTopRightRadius: 33,
              }}
            >
              <LoanInformation />
            </Box>
            <Box
              mt={0.5}
              px={{ xs: 0, md: 4 }}
              py={{ xs: 2, md: 3 }}
              bgcolor={theme.palette.background.paper}
              sx={{
                scrollbarWidth: "none",
                borderBottomLeftRadius: 33,
                borderBottomRightRadius: 33,
              }}
            >
              <Timeline position="right" sx={{ p: 0 }}>
                {!isBorrower &&
                  showDocUploadModal &&
                  Boolean(docsToUpload.length) && <UploadDocumentB2 />}
                {((isBorrower && borrowerWithoutHardpull) ||
                  (!isBorrower && coborrowerWithoutHardpull)) && (
                  <ConsentForHardpull isBorrower={isBorrower} />
                )}
                {isBorrower &&
                  showAddJACoborrower &&
                  !coborrowerInviteIsOpen && <JointAccountAnnouncement />}
                {isBorrower && <OfferInformation />}
                {isBorrower && showAddCoborrower && <NPEAnnouncement />}
                <IdVerification trackerStatusColor={trackerStatusColor} />
                <CreditVerification trackerStatusColor={trackerStatusColor} />
                {isBorrower && (
                  <HomeValueVerification
                    trackerStatusColor={trackerStatusColor}
                  />
                )}
                <IncomeVerification trackerStatusColor={trackerStatusColor} />
                {isBorrower && <AnnuncementCoborrowerPending />}
                {isBorrower && <AnnuncementCreateNewOfferPending />}
                {isBorrower && <FinalOfferVerification />}
                <DemographicDataVerification
                  trackerStatusColor={trackerStatusColor}
                />
                {hasLoanOfficer && (
                  <BrokerDisclosuresVerification
                    trackerStatusColor={trackerStatusColor}
                  />
                )}
                <InitialDisclosuresVerification
                  trackerStatusColor={trackerStatusColor}
                />
                {isBorrower && (
                  <InsuranceVerification
                    trackerStatusColor={trackerStatusColor}
                  />
                )}

                {isBorrower && (
                  <TitleVerification trackerStatusColor={trackerStatusColor} />
                )}

                <ScheduleClosingVerification
                  trackerStatusColor={trackerStatusColor}
                />

                <ClosingDisclosuresVerification
                  trackerStatusColor={trackerStatusColor}
                />
                {isBorrower && (
                  <FundingVerification
                    trackerStatusColor={trackerStatusColor}
                  />
                )}
              </Timeline>
            </Box>
          </Box>
          <QuestionsBox />
        </Fragment>
      }
    </Container>
  );
};

export default CTracker;
