import useUser from "context/UserCustomer/useUserCustomerContext";
import { useEffect, useState } from "react";
import Loan from "typedef/Loan";
import API from "utils/API";
import { errorMessages } from "utils/errorMessages";

const useLoan = () => {
  const [loan, setLoan] = useState<Loan>();
  const { user, logout } = useUser();

  useEffect(() => {
    if (user) {
      API.get<Loan>(`/get/my-loan`).then((response) => {
        if ("error" in response) {
          console.error(response);
        } else {
          const isBorrower = user?.id === response?.data?.borrowerId;
          const stepName = isBorrower ? "borrowerSteps" : "coborrowerSteps";
          const currentStep =
            response?.data?.[stepName]?.creditVerification?.adminError;
          if (
            response?.data?.loanStatusCode === "ARCHIVED" &&
            currentStep !== errorMessages.creditVerification.adminError
          ) {
            logout();
          }
          setLoan(response.data);
        }
      });
    }
  }, [user, logout]);

  return loan;
};

export default useLoan;
