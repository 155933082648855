import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useUser from "context/UserCustomer/useUserCustomerContext";

type Inputs = {
  email: string;
  password: string;
};

const useLoginForm = () => {
  const { login } = useUser();
  const [searchParams] = useSearchParams();
  const emailParam = searchParams.get("email");

  const [loginError, setLoginError] = useState<string>();
  const form = useForm<Inputs>({
    defaultValues: {
      email: emailParam ?? "",
    },
  });

  const emailWatch = form.watch("email");
  const passwordWatch = form.watch("password");

  const onSubmit = form.handleSubmit(async (data) => {
    const result = await login(data.email?.trim(), data.password);
    if (result) {
      setLoginError(result);
    }
  });

  useEffect(() => {
    setLoginError(undefined);
  }, [emailWatch, passwordWatch]);

  return {
    onSubmit,
    loginError,
    ...form,
  };
};

export default useLoginForm;
