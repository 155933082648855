import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { Fragment } from "react/jsx-runtime";
import { HELOCFormProps } from "typedef/HELOCFormProps";

const INTEREST_RATE_MULTIPLIER = 10000;
const PERCENTAGE = 100;
const AMOUNT_OF_DECIMALS = 2;

const InterestRate: FC<HELOCFormProps> = ({ newValuesSlider }) => {
  const theme = useTheme();
  const monthlyPaymentInitialDraw = newValuesSlider?.monthlyPaymentInitialDraw;
  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 2fr"
      alignItems="center"
      sx={{ backgroundColor: theme.palette.background.paper, p: 2 }}
    >
      <Typography
        variant="h6"
        fontFamily="Pro Display Regular"
        color={theme.palette.text.primary}
      >
        Interest Rate:
      </Typography>
      <Box display="flex" alignItems="center">
        <Typography
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "baseline",
          }}
          variant="h6"
          color={theme.palette.text.primary}
          fontSize={"1rem"}
        >
          {newValuesSlider ? (
            <Fragment>
              <span
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bolder",
                  color: theme.palette.text.primary,
                }}
              >
                {(
                  Math.floor(
                    (newValuesSlider?.interestRate ?? 0) *
                      INTEREST_RATE_MULTIPLIER,
                  ) / PERCENTAGE
                ).toFixed(AMOUNT_OF_DECIMALS)}
                %
              </span>
              <span
                style={{
                  marginLeft: 20,
                  color: theme.palette.text.disabled,
                }}
              >
                {"("}
                <span
                  style={{
                    color: theme.palette.text.disabled,
                  }}
                >
                  $
                  {Number(
                    monthlyPaymentInitialDraw?.toFixed(0),
                  )?.toLocaleString("en-US")}
                </span>{" "}
                Est. Monthly Payment
                {")"}
              </span>
            </Fragment>
          ) : (
            <Skeleton width="75%" height={"1.5rem"} />
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default InterestRate;
