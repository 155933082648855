import API from "utils/API";
import Loan from "typedef/Loan";
import { useNavigate } from "react-router-dom";
import { useState, useCallback, useEffect } from "react";

export type Marks = Loan["initialOffer"][];

const useOffer = () => {
  const navigate = useNavigate();
  const [loan, setLoan] = useState<Loan>();
  const [loading, setLoading] = useState(true);
  const [marks, setMarks] = useState<Marks>([]);
  const [isAccepting, setIsAccepting] = useState(false);
  const [markSelectIndex, setMarkSelectIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const saveOffer = useCallback(async () => {
    setIsAccepting(true);
    const response = await API.post({
      url: `/accept-offer/initial?ignoreMaxDti=true`,
      data: { initialOffer: marks[markSelectIndex], loanId: loan?.id },
    });
    if ("error" in response) {
      console.error(response);
      setErrorMessage(response.error);
      setShowErrorMessage(true);
    } else {
      navigate("/type-of-credit-line", { replace: true });
    }

    setIsAccepting(false);
  }, [navigate, marks, markSelectIndex, loan?.id]);

  useEffect(() => {
    if (marks.length === 1 && marks[markSelectIndex].amount) {
      setLoading(true);
      saveOffer().finally(() => {
        setLoading(false);
      });
    }
    //eslint-disable-next-line
  }, [marks, saveOffer]);

  useEffect(() => {
    (async () => {
      setShowErrorMessage(false);
      setLoading(true);
      const loanResponse = await API.get<Loan>(`/get/my-loan`);
      if ("error" in loanResponse) {
        setShowErrorMessage(true);
        setErrorMessage(loanResponse.error);
      } else {
        setLoan(loanResponse.data);
        const { id: loanId } = loanResponse.data;
        const offersResponse = await API.get<Marks>(
          `/get-offer/initial?loanId=${loanId}&ignoreMaxDti=true`,
        );
        if ("error" in offersResponse) {
          setShowErrorMessage(true);
          setErrorMessage(offersResponse.error);
        } else {
          setMarks(offersResponse.data);
        }
      }
      setLoading(false);
    })();
  }, []);

  return {
    loan,
    loading,
    saveOffer,
    isAccepting,
    errorMessage,
    showErrorMessage,
    marks,
    markSelectIndex,
    setMarkSelectIndex,
  };
};

export default useOffer;
