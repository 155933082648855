import { useState } from "react";
import Typography from "@mui/material/Typography";
import DemographicDataDialog from "./DemographicDataDialog";
import CTrackerTimelineItem from "components/CTimelineItem";
import useTracker from "components/CTracker/useTrackerContext";
import { TrackerStatusColor } from "typedef/TrackerStatusColor";

const DemographicDataVerification: React.FC<{
  trackerStatusColor: TrackerStatusColor;
}> = ({ trackerStatusColor }) => {
  const { loan, type } = useTracker();
  const isBorrower = type === "borrower";
  const step = isBorrower ? loan?.borrowerSteps : loan?.coborrowerSteps;
  const prevStep = loan?.borrowerFlags?.finalOfferAccepted;

  const status = prevStep
    ? step?.demographicsVerification?.status ?? "pending"
    : "pending";
  const message = step?.demographicsVerification?.message ?? "";
  const [demographicsDialogOpen, setDemographicsDialogOpen] = useState(false);

  return (
    <CTrackerTimelineItem
      status={status}
      title="Demographics"
      tooltip="We collect demographic data as part of federal requirements. The Home Mortgage Disclosure Act (HMDA) is a federal law that requires lenders to share their mortgage data so it's possible to better determine and dismantle credit discrimination practices."
      {...(prevStep &&
        status === "analyzing" && {
          action: {
            message: "Add Demographics Data",
            onClick: () => setDemographicsDialogOpen(true),
          },
        })}
    >
      <Typography variant="h6" color={[trackerStatusColor[status]]}>
        {status === "analyzing" && message}
        {status === "failed" && message}
        {status === "success" && message}
      </Typography>
      <DemographicDataDialog
        open={demographicsDialogOpen}
        onClose={() => setDemographicsDialogOpen(false)}
      />
    </CTrackerTimelineItem>
  );
};

export default DemographicDataVerification;
