import CTracker from "components/CTracker";
import { TrackerContextProvider } from "components/CTracker/useTrackerContext";
import useUserCustomerContext from "context/UserCustomer/useUserCustomerContext";
import { Navigate } from "react-router-dom";
import ReapplicationModal from "./ReapplicationModal";

const BorrowerTracker: React.FC = () => {
  const { loan, user } = useUserCustomerContext();

  const isCoborrower = user && user.id === loan?.coborrowerId;
  const isLoanArchived = loan?.borrowerFlags?.archiveType;

  if (isLoanArchived) {
    return <ReapplicationModal loan={loan} />;
  }

  if (isCoborrower) {
    return <Navigate to="/coborrower-tracker" replace={true} />;
  }

  return (
    <TrackerContextProvider>
      <CTracker isBorrower={true} />
    </TrackerContextProvider>
  );
};

export default BorrowerTracker;
