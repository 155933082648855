import { FC, useState } from "react";
import { CircularProgress, Typography, useTheme } from "@mui/material";
import CDialog from "components/CDialog";
import Loan from "typedef/Loan";
import API from "utils/API";
import { useNavigate } from "react-router-dom";
import useUserCustomerContext from "context/UserCustomer/useUserCustomerContext";
import CButton from "components/CButton";

interface Props {
  loan: Loan;
}

const ReapplicationModal: FC<Props> = ({ loan }) => {
  const { logout } = useUserCustomerContext();
  const navigate = useNavigate();
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(false);
  const [showLOMessage, setShowLOMessage] = useState(false);
  const [buttonMessage, setButtonMessage] = useState("Reapply");

  const handleLogOut = async () => {
    const isOrganic = !loan?.loanOfficerId;
    const isLOLink =
      loan?.loanOfficerId && loan?.borrowerFlags?.completedByBorrower;
    const isPreQual =
      loan?.loanOfficerId && !loan?.borrowerFlags?.completedByBorrower;

    setIsLoading(true);

    if (isPreQual) {
      await API.post({
        url: `/save-to-loan/flags?entity=borrower&loanId=${loan?.id}`,
        data: {
          hasReapplied: true,
        },
      });

      setIsLoading(false);
      setShowLOMessage(true);
      setButtonMessage("OK");
    } else if (isLOLink) {
      const brokerForInviteLink = loan?.broker?.lenderName
        ?.replace(" ", "-")
        ?.toLowerCase();
      const loanOfficerName = loan?.loanOfficer?.firstName?.toLowerCase();
      const loanOfficerLastName = loan?.loanOfficer?.lastName?.toLowerCase();
      const loanOfficerNameForInviteLink = `${loanOfficerName}-${loanOfficerLastName}`;

      const inviteLink = `/invite/${brokerForInviteLink}/${loanOfficerNameForInviteLink}/signup`;

      logout();
      navigate(inviteLink, { replace: true });
    } else if (isOrganic) {
      logout();
      navigate("/signup", { replace: true });
    }
  };

  return (
    <CDialog
      open
      title="Loan Archived"
      description="Your application has been archived."
      icon="cancel"
    >
      {showLOMessage ? (
        <Typography
          variant="body2"
          sx={{ color: theme.palette.text.primary }}
          marginBottom={3}
          fontSize={20}
        >
          A notification was sent to your loan officer. Please wait for an email
          to start a new application.
        </Typography>
      ) : null}
      <CButton
        onClick={() => {
          if (showLOMessage) {
            logout();
          } else {
            handleLogOut();
          }
        }}
        variant="contained"
        name="reapply-button"
        disabled={isLoading}
        color="primary"
        sx={{ margin: "0 auto" }}
      >
        {isLoading ? <CircularProgress size={32} /> : buttonMessage}
      </CButton>
    </CDialog>
  );
};

export default ReapplicationModal;
