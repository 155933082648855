import {
  Box,
  Checkbox,
  FormGroup,
  Typography,
  FormControlLabel,
  useTheme,
  Collapse,
  IconButton,
} from "@mui/material";
import { FC, Fragment, useState, useCallback } from "react";
import CTextField from "components/CTextField";
import { UseFormReturn } from "react-hook-form";
import DemographicsDataChecks from "typedef/DemographicsDataChecks";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

type InnerFormProps = UseFormReturn<DemographicsDataChecks, unknown>;

const EthnicityOptions: FC<{ form: InnerFormProps }> = ({ form }) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandClick = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  const handleCheckboxChange = useCallback(
    (field: keyof DemographicsDataChecks["ethnicity"]) => () => {
      form.setValue(
        `ethnicity.${field}`,
        !form.getValues(`ethnicity.${field}`),
        { shouldDirty: true, shouldValidate: true },
      );
    },
    [form],
  );

  return (
    <Fragment>
      <Box
        mt={2}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h5" color={theme.palette.text.primary}>
          Ethnicity
        </Typography>
        <Typography variant="subtitle2" color={theme.palette.text.secondary}>
          Check one or more
        </Typography>
      </Box>
      <Box>
        <FormGroup>
          <Box display="flex" alignItems="center">
            <FormControlLabel
              label="Hispanic or Latino"
              control={
                <Checkbox
                  checked={form.getValues().ethnicity.hispanicOrLatino}
                  onChange={handleCheckboxChange("hispanicOrLatino")}
                />
              }
            />
            <IconButton
              onClick={handleExpandClick}
              sx={{
                padding: 0,
                borderRadius: "50%",
                backgroundColor: theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
                color: theme.palette.primary.contrastText,
                marginLeft: 2,
              }}
            >
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Box pl={3}>
              <FormGroup>
                <FormControlLabel
                  label="Mexican"
                  control={
                    <Checkbox
                      checked={form.getValues().ethnicity.mexican}
                      onChange={handleCheckboxChange("mexican")}
                    />
                  }
                />
                <FormControlLabel
                  label="Puerto Rican"
                  control={
                    <Checkbox
                      checked={form.getValues().ethnicity.puertoRican}
                      onChange={handleCheckboxChange("puertoRican")}
                    />
                  }
                />
                <FormControlLabel
                  label="Cuban"
                  control={
                    <Checkbox
                      checked={form.getValues().ethnicity.cuban}
                      onChange={handleCheckboxChange("cuban")}
                    />
                  }
                />
                <FormControlLabel
                  label="Other Hispanic or Latino"
                  control={
                    <Checkbox
                      checked={form.getValues().ethnicity.otherHispanicOrLatino}
                      onChange={handleCheckboxChange("otherHispanicOrLatino")}
                    />
                  }
                />
              </FormGroup>
              {form.watch("ethnicity.otherHispanicOrLatino") && (
                <Fragment>
                  <Typography
                    color={theme.palette.text.secondary}
                    variant="subtitle2"
                    textAlign={"initial"}
                  >
                    Type origin, for example, Argentinean, Colombian, Dominican,
                    Nicaraguan, Salvadoran, Spaniard, and so on:
                  </Typography>
                  <CTextField
                    error={
                      form.formState.errors.ethnicity?.customEthnicity?.message
                    }
                    fullWidth
                    type="text"
                    placeholder="Type origin"
                    {...form.register("ethnicity.customEthnicity", {
                      required: {
                        value: true,
                        message: "Other Hispanic or Latino is required.",
                      },
                      minLength: {
                        value: 2,
                        message:
                          "The Other Hispanic or Latino must be at least 2 characters long.",
                      },
                      maxLength: {
                        value: 120,
                        message:
                          "The Other Hispanic or Latino cannot be longer than 120 characters.",
                      },
                      pattern: {
                        value:
                          /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<,>;:[\]]{2,}$/,
                        message:
                          "The Other Hispanic or Latino format is incorrect.",
                      },
                    })}
                  />
                </Fragment>
              )}
            </Box>
          </Collapse>
          <FormControlLabel
            label="Not Hispanic or Latino"
            control={
              <Checkbox
                checked={form.getValues().ethnicity.notHispanicOrLatino}
                onChange={handleCheckboxChange("notHispanicOrLatino")}
              />
            }
          />
          <FormControlLabel
            label="I do not wish to provide this Information"
            control={
              <Checkbox
                checked={form.getValues().ethnicity.prefNoSay}
                onChange={handleCheckboxChange("prefNoSay")}
              />
            }
          />
        </FormGroup>
      </Box>
    </Fragment>
  );
};

export default EthnicityOptions;
