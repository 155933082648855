import React, { useEffect, useState } from "react";
import { Box, Typography, Skeleton, Slider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CDialog from "components/CDialog";
import CButton from "components/CButton";
import { PrivateLabelFees } from "typedef/PrivateLabelFees";
import Loan from "typedef/Loan";
import { Controller, useForm } from "react-hook-form";
import WarningIcon from "@mui/icons-material/Warning";
import { Close } from "@mui/icons-material";
import {
  ValuesHELOC,
  ValuesSliderProps,
} from "screens/TypeOfCreditLine/useDefineLoanType";

interface DrawRequestModalProps {
  loan: Loan | undefined;
  fees: PrivateLabelFees | undefined;
  showSlider: boolean | undefined;
  openModal: boolean;
  onClose: (event?: React.SyntheticEvent, reason?: string) => void;
  initialValuesSliderHELOC: ValuesSliderProps | undefined;
  marks: {
    value: number;
  }[];
  recalculateOffer: (value: number | number[]) => void;
  loading: boolean;
  setDrawLessThan100: (value: boolean) => void;
  drawLessThan100?: boolean;
  newValuesSlider: ValuesHELOC | undefined;
}

interface FormData {
  newLoanAmount: number;
}

type ExtendedFormData = Omit<FormData, "newLoanAmount"> & {
  newLoanAmount: number;
};

const DrawRequestModal: React.FC<DrawRequestModalProps> = ({
  showSlider,
  openModal,
  onClose,
  initialValuesSliderHELOC,
  marks,
  loading,
  recalculateOffer,
  setDrawLessThan100,
  drawLessThan100,
  newValuesSlider,
}) => {
  const theme = useTheme();
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
    setValue,
  } = useForm<ExtendedFormData>();
  const [drawAmmountValue, setDrawAmmountValue] = useState<number | undefined>(
    initialValuesSliderHELOC?.maxDraw,
  );
  const [sliderValue, setSliderValue] = useState<number>(
    newValuesSlider?.initialDrawAmount ?? 0,
  );

  const handlerChangeDraw = (value: number) => {
    if (
      initialValuesSliderHELOC?.maxDraw &&
      initialValuesSliderHELOC?.maxDraw - value
    ) {
      if (typeof setDrawLessThan100 === "function") {
        setDrawLessThan100(true);
      }
    } else if (typeof setDrawLessThan100 === "function") {
      setDrawLessThan100(false);
    }

    setDrawAmmountValue(value);
    setSliderValue(value);
    setValue("newLoanAmount", value);
  };

  const handleSliderChange = (_event: Event, newValue: number | number[]) => {
    setSliderValue(newValue as number);
    // @ts-ignore
    handlerChangeDraw(newValue);
    setValue("newLoanAmount", newValue as number);
  };

  const handleSliderChangeCommitted = (
    _event: React.SyntheticEvent | Event,
    newValue: number | number[],
  ) => {
    if (typeof newValue === "number") {
      handlerChangeDraw(newValue);
    }
  };

  const onSubmit = async (data: ExtendedFormData) => {
    try {
      const newLoanAmount = Number(data.newLoanAmount);
      if (isNaN(newLoanAmount)) {
        throw new Error("Invalid loan amount");
      }
      handlerChangeDraw(newLoanAmount);
      recalculateOffer(newLoanAmount);
      if (onClose) {
        onClose();
      }
      // eslint-disable-next-line
    } catch (error) {}
  };

  useEffect(() => {
    if (drawAmmountValue === undefined) {
      setDrawAmmountValue(initialValuesSliderHELOC?.maxDraw);
      setSliderValue(initialValuesSliderHELOC?.maxDraw ?? 0);
    }
    // eslint-disable-next-line
  }, [initialValuesSliderHELOC?.maxDraw]);

  return (
    <CDialog maxWidth="xs" open={openModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="300px"
        >
          <Box width="300px" mb={2}>
            <Typography
              variant="h5"
              color={theme.palette.text.primary}
              textAlign="justify"
              fontFamily="Pro Display Bold"
              width="100%"
              marginBottom="10px"
            >
              Draw Request
              <Close
                fontSize="large"
                htmlColor={theme.palette.text.primary}
                style={{ cursor: "pointer", marginLeft: "112px" }}
                onClick={() => {
                  onClose();
                  setDrawLessThan100(false);
                }}
              />
            </Typography>
            <Typography
              variant="h6"
              color={theme.palette.text.primary}
              textAlign="justify"
              fontFamily="Pro Display Semibold"
              width="100%"
            >
              Available Draw: $
              {Math.floor(
                initialValuesSliderHELOC?.maxDraw ?? 0,
              ).toLocaleString("en-US")}
            </Typography>
          </Box>
          <Typography
            color={theme.palette.text.primary}
            textAlign="justify"
            fontSize={13}
            fontFamily="Pro Display Semibold"
            width="100%"
          >
            Draw: ${Math.floor(sliderValue ?? 0).toLocaleString("en-US")}
          </Typography>
          {showSlider && (
            <>
              {!initialValuesSliderHELOC?.maxDraw &&
              !initialValuesSliderHELOC?.minDraw ? (
                <Skeleton width="300px" height="2.8rem" />
              ) : (
                initialValuesSliderHELOC?.minDraw !==
                  initialValuesSliderHELOC?.maxDraw && (
                  <Box
                    py={1}
                    sx={{
                      borderTop: { xs: "0.5px solid lightGrey", md: "none" },
                      borderBottom: { xs: "0.5px solid lightGrey", md: "none" },
                      width: "300px",
                    }}
                  >
                    <Controller
                      name="newLoanAmount"
                      control={control}
                      render={({ field: _field }) => (
                        <Slider
                          sx={{
                            "& .MuiSlider-thumb": {
                              height: 25,
                              borderRadius: "4px",
                              clipPath:
                                "polygon(0 0, 100% 0, 100% 50%, 50% 100%, 0 50%)",
                            },
                            "& .MuiSlider-rail": {
                              height: 15,
                              color: theme.palette.text.secondary,
                            },
                            "& .MuiSlider-mark": {
                              width: 6,
                              height: 6,
                              borderRadius: 5,
                              color: theme.palette.background.paper,
                            },
                            "& .MuiSlider-track": {
                              height: 15,
                              opacity: "0%",
                              color: theme.palette.text.secondary,
                            },
                            "& .MuiSlider-markLabel": {
                              color: theme.palette.text.secondary,
                              fontFamily: "Pro Display Regular",
                            },
                          }}
                          aria-label="Price"
                          marks={marks}
                          disabled={loading}
                          value={sliderValue}
                          onChange={handleSliderChange}
                          onChangeCommitted={handleSliderChangeCommitted}
                          getAriaValueText={(value) => `${value}`}
                          min={initialValuesSliderHELOC?.minDraw ?? 0}
                          max={initialValuesSliderHELOC?.maxDraw ?? 0}
                          step={1000}
                        />
                      )}
                    />
                    <Box display="flex" justifyContent="space-between">
                      <Typography
                        color={theme.palette.text.secondary}
                        variant="subtitle2"
                      >
                        {`75%`}
                      </Typography>
                      <Typography
                        color={theme.palette.text.secondary}
                        variant="subtitle2"
                      >
                        {`100%`}
                      </Typography>
                    </Box>
                  </Box>
                )
              )}
            </>
          )}
          {drawLessThan100 && (
            <Box
              mt={2}
              mb={2}
              px={{ xs: 1, md: 0 }}
              display="flex"
              alignItems="left"
            >
              <WarningIcon
                style={{ fontSize: 25, marginRight: 5, marginTop: 5 }}
                htmlColor={theme.palette.warning.main}
              />
              <Typography
                color={theme.palette.text.primary}
                fontSize={{ xs: ".4rem", md: ".9rem" }}
                marginTop={1}
              >
                Draw is less than 100%
              </Typography>
            </Box>
          )}
          <CButton
            type="submit"
            disabled={Boolean(errors.newLoanAmount)}
            loading={isSubmitting}
            variant="contained"
            fullWidth
            sx={{ marginTop: 2 }}
          >
            Confirm Draw
          </CButton>
          <CButton
            type="reset"
            variant="outlined"
            fullWidth
            sx={{ marginTop: 2 }}
            onClick={() => {
              onClose();
              setDrawLessThan100(false);
            }}
          >
            Cancel
          </CButton>
        </Box>
      </form>
    </CDialog>
  );
};

export default DrawRequestModal;
