import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CDocUploadDocumentDialog, {
  NeededDoc,
} from "components/CTracker/Steps/AddMissingDocsDialog";
import { useState } from "react";
import { Fragment } from "react/jsx-runtime";
import Loan from "typedef/Loan";
import { getDocsMessage } from "utils/missingDocsList";

interface UploadEachDocumentProps {
  docsToUpload: NeededDoc[];
  loan?: Loan;
}

const UploadEachDocument: React.FC<UploadEachDocumentProps> = ({
  docsToUpload,
  loan,
}) => {
  const [docToUpload, setDocToUpload] = useState<NeededDoc>();
  return (
    <Fragment>
      {docsToUpload
        ?.filter(
          (doc: NeededDoc) =>
            doc?.submitted === false || doc?.rejected === true,
        )
        .map((doc: NeededDoc, index: number) => {
          return (
            <>
              {doc?.rejected ? (
                <Typography
                  key={`${index}${doc?.docName}`}
                  fontSize={18}
                  variant="h6"
                  color={"#ff3d00"}
                >
                  The {doc.docName} uploaded was rejected.
                  <span style={{ color: "#808080" }}>
                    {" "}
                    Reason: {doc?.rejectReason}
                  </span>
                </Typography>
              ) : (
                <Typography
                  key={index}
                  fontSize={18}
                  variant="h6"
                  color={"#808080"}
                >
                  {getDocsMessage(
                    doc.docName,
                    doc.isBorrower
                      ? loan?.borrowerFirstName
                      : loan?.coborrowerFullName?.split(" ")[0],
                    doc?.yearRequestedByAdmin ?? undefined,
                    doc?.companyName,
                    doc?.lienBalance,
                  )}
                </Typography>
              )}
              {doc.docName === "Payoff Texas 50(a)6" ? (
                <Box sx={{ gap: "20px", display: "flex" }}>
                  <div>
                    <Button
                      aria-label={`Upload paid and closed letter`}
                      name="uploadEachDocument-paidAndClosedLetter"
                      variant="contained"
                      sx={{
                        marginTop: 1,
                        whiteSpace: "nowrap",
                        borderRadius: "25px",
                        marginBottom: 1,
                        textTransform: "inherit",
                      }}
                      onClick={() => {
                        setDocToUpload({ ...doc, paidAndClosedLetter: true });
                      }}
                    >
                      Upload paid and closed letter
                    </Button>
                    <Typography
                      sx={{
                        marginBottom: 2,
                        fontSize: "0.8rem",
                        paddingLeft: "5px",
                      }}
                    >
                      Maximum file size allowed: 100MB
                    </Typography>
                  </div>

                  <div>
                    <Button
                      aria-label={`Upload a payoff statement`}
                      name="uploadEachDocument-payoffStatement"
                      variant="contained"
                      sx={{
                        marginTop: 1,
                        whiteSpace: "nowrap",
                        borderRadius: "25px",
                        marginBottom: 1,
                        textTransform: "inherit",
                      }}
                      onClick={() => {
                        setDocToUpload(doc);
                      }}
                    >
                      Upload a payoff statement
                    </Button>
                    <Typography
                      sx={{
                        marginBottom: 2,
                        fontSize: "0.8rem",
                        paddingLeft: "5px",
                      }}
                    >
                      Maximum file size allowed: 100MB
                    </Typography>
                  </div>
                </Box>
              ) : (
                <div>
                  <Button
                    aria-label={`Upload ${doc?.docName}`}
                    name={`uploadEachDocument-uploadDoc`}
                    variant="contained"
                    sx={{
                      marginTop: 1,
                      whiteSpace: "nowrap",
                      borderRadius: "25px",
                      marginBottom: 1,
                      textTransform: "inherit",
                    }}
                    onClick={() => {
                      setDocToUpload(doc);
                    }}
                  >
                    Upload {doc?.docName}
                  </Button>
                  <Typography
                    sx={{
                      marginBottom: 2,
                      fontSize: "0.8rem",
                      paddingLeft: "5px",
                    }}
                  >
                    Maximum file size allowed: 100MB
                  </Typography>
                </div>
              )}
            </>
          );
        })}

      {docToUpload && (
        <CDocUploadDocumentDialog
          open={Boolean(docToUpload)}
          handleClose={() => setDocToUpload(undefined)}
          neededDocs={[docToUpload]}
          singleDocument={true}
        />
      )}
    </Fragment>
  );
};

export default UploadEachDocument;
