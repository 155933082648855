import { ReactNode } from "react";
import {
  SimplePaletteColorOptions,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";

export const MainThemeUI: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { privateLabel } = usePrivateLabel();

  const theme = createTheme({
    palette: privateLabel?.pallete,
    typography: {
      fontFamily: [
        '"Pro Display Regular"',
        '"Pro Text Semibold"',
        '"Pro Display Bold"',
        '"Pro Display Semibold"',
        '"Pro Display Medium"',
      ].join(","),
      h1: { fontFamily: "Pro Display Bold" },
      h2: { fontFamily: "Pro Display Semibold" },
      h3: { fontFamily: "Pro Display Semibold" },
      h4: { fontFamily: "Pro Display Semibold" },
      h5: { fontFamily: "Pro Display Regular" },
      h6: { fontFamily: "Pro Display Regular" },
      subtitle1: { fontFamily: "Pro Display Regular" },
      subtitle2: { fontFamily: "Pro Display Regular" },
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            color: privateLabel?.pallete?.text?.secondary,
            borderRadius: "12px",
            background: privateLabel?.pallete?.background?.paper,
            fontFamily: "Pro Display Regular",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          contained: {
            borderColor: privateLabel?.pallete?.background?.paper,
            backgroundColor: (
              privateLabel?.pallete?.primary as SimplePaletteColorOptions
            ).main,
            borderWidth: "0.01rem !important",
          },
          outlined: {
            borderColor: (
              privateLabel?.pallete?.primary as SimplePaletteColorOptions
            )?.main,
            backgroundColor: privateLabel?.pallete?.background?.paper,
            borderWidth: "0.01rem !important",
            ":hover": {
              backgroundColor: (
                privateLabel?.pallete?.primary as SimplePaletteColorOptions
              )?.light,
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paperWidthXs: {
            maxWidth: 410,
          },
          paperWidthMd: {
            maxWidth: 500,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& legend": {
              width: "0",
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          formControl: {
            position: "relative",
          },
          root: {
            transform: "unset !important",
            padding: "0 0.2rem",
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            gap: "4px",
          },
        },
      },
    },
  });
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
