import Address from "typedef/Address";
import { FC } from "react";
import { Autocomplete, Typography, useTheme } from "@mui/material";
import CTextField, { CTextFieldProps } from "components/CTextField";
import addressToString from "./addressToString";
import useAddressAutoComplete from "./useAddressAutocomplete";

export type CAddressAutocompleteProps = CTextFieldProps & {
  address?: Address | null;
  setAddress(address: Address | null): void;
  noLabel?: boolean;
  isInvalidStateId?: boolean | string;
};

const CAddressAutocomplete: FC<CAddressAutocompleteProps> = ({
  address,
  setAddress,
  noLabel,
  isInvalidStateId,
  ...props
}) => {
  const addressAutocomplete = useAddressAutoComplete({ address, setAddress });
  const theme = useTheme();
  const streetLine = address?.street_line || "";
  return (
    <Autocomplete
      open={addressAutocomplete.open}
      color="primary"
      value={address}
      clearOnBlur
      loading={addressAutocomplete.loading}
      options={addressAutocomplete.options}
      inputValue={addressAutocomplete.text}
      onChange={addressAutocomplete.handleOnChange}
      onInputChange={addressAutocomplete.handleOnInputChange}
      onOpen={props.disabled ? () => null : addressAutocomplete.handleOnOpen}
      onClose={addressAutocomplete.handleOnClose}
      getOptionLabel={addressToString}
      isOptionEqualToValue={(option, value) =>
        option.street_line === value?.street_line
      }
      popupIcon={false}
      noOptionsText={"Please enter your home address"}
      filterOptions={() => [
        {
          city: "",
          state: "",
          zipcode: "",
          entries: 0,
          secondary: "",
          street_line: "My address is not listed",
        },
        ...addressAutocomplete.options,
      ]}
      renderInput={(params) => (
        <>
          <CTextField
            {...props}
            {...params}
            disabled={props.disabled}
            placeholder={props.placeholder}
            label={noLabel ? undefined : props.label}
            InputLabelProps={{
              shrink: true,
              ...(noLabel && {
                "aria-hidden": "true",
                // eslint-disable-next-line
                ["data-acsb-hidden" as any]: "true",
                // eslint-disable-next-line
                ["data-acsb-force-hidden" as any]: "true",
              }),
              ...props.InputLabelProps,
            }}
            InputProps={{
              ...params.InputProps,
              ...props.InputProps,
              style: {
                pointerEvents: props.disabled ? "none" : "auto",
              },
              "aria-autocomplete": "list",
              "aria-expanded": false,
              autoCapitalize: "none",
              spellCheck: false,
              // @ts-expect-error
              "data-acsb-navigable": true,
              "data-acsb-now-navigable": true,
              "aria-hidden": false,
              "data-acsb-hidden": false,
              "data-acsb-field-visible": true,
              "aria-label": props.placeholder,
              "data-acsb-tooltip": props.placeholder,
            }}
            fullWidth
          />
          {streetLine !== "My address is not listed" && isInvalidStateId && (
            <Typography
              mt={-2}
              mb={2}
              ml={1}
              variant="body1"
              lineHeight={1}
              color={theme.palette.error.main}
              fontSize={{ xs: "0.75rem" }}
            >
              {isInvalidStateId}
            </Typography>
          )}
        </>
      )}
      sx={{
        "& .MuiOutlinedInput-root": {
          color: theme.palette.text.primary,
        },
        "& .MuiAutocomplete-listbox": {
          color: theme.palette.background.paper,
        },
        "& .MuiInputLabel-asterisk": {
          display: "none",
        },
        "& .MuiAutocomplete-clearIndicator": {
          display: props.disabled ? "none" : "auto",
        },
      }}
    />
  );
};

export default CAddressAutocomplete;
