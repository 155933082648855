import API from "utils/API";
import moment from "moment";
import { useForm } from "react-hook-form";
import parseMoney from "utils/parseMoney";
import { useEffect, useState } from "react";

type UseMakePaymentProps = {
  loanId: string;
};

type NextPaymentProps = {
  nextPaymentDate?: string;
  amount?: number;
  isPayable?: boolean;
};

const useMakePayment = ({ loanId }: UseMakePaymentProps) => {
  const form = useForm();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [typePayment, setTypePayment] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [amountPayment, setAmountPayment] = useState<number>();
  const [openModalThanks, setOpenModalThanks] = useState(false);
  const [nextPayment, setNextPayment] = useState<NextPaymentProps>();

  useEffect(() => {
    if (loanId) {
      API.get<NextPaymentProps>(`/next-payment?loanId=${loanId}`).then(
        (res) => {
          if ("error" in res) {
            console.error(res);
          } else {
            setNextPayment(res.data);
          }
        },
      );
    }
  }, [loanId]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const clearError = () => {
    setError(undefined);
  };

  const handleOpenModalThanks = () => {
    handleCloseModal();
    setOpenModalThanks(true);
  };
  const handleCloseModalThanks = () => {
    setOpenModalThanks(false);
  };

  const payNow = form.handleSubmit(async () => {
    setLoading(true);
    const response = await API.post({
      url: `/pay-next-payment?loanId=${loanId}`,
    });
    if ("error" in response) {
      setError(response.error);
    } else {
      handleCloseModal();
      setTypePayment("next");
      handleOpenModalThanks();
      form.reset();
    }
    setLoading(false);
  });

  const onSubmit = form.handleSubmit(async (data) => {
    setLoadingSubmit(true);
    const response = await API.post({
      url: `/pay-principal-payment?loanId=${loanId}`,
      data: {
        amount: parseMoney(data?.amountToPay),
        effectiveDate: moment(data?.paymentDate, "MM-DD-YYYY").toISOString(),
      },
    });
    if ("error" in response) {
      setError(response.error);
    } else {
      handleCloseModal();
      setTypePayment("principal");
      setAmountPayment(data?.amountToPay);
      handleOpenModalThanks();
      form.reset();
      clearError();
    }
    setLoadingSubmit(false);
  });

  return {
    form,
    error,
    payNow,
    loading,
    onSubmit,
    openModal,
    nextPayment,
    clearError,
    typePayment,
    amountPayment,
    loadingSubmit,
    openModalThanks,
    handleOpenModal,
    handleCloseModal,
    handleOpenModalThanks,
    handleCloseModalThanks,
  };
};

export default useMakePayment;
