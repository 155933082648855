import API from "utils/API";
import Loan from "typedef/Loan";
import HELOCOffer from "./HELOCOffer";
import HELOANOffer from "./HELOANOffer";
import CLoader from "components/CLoader";
import { Fragment, useEffect, useState } from "react";

const FinalOffer: React.FC = () => {
  const [loan, setLoan] = useState<Loan>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    API.get<Loan>(`/get/my-loan`)
      .then((response) => {
        if ("error" in response) {
          console.error(response);
        } else {
          setLoan(response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Fragment>
      <CLoader loading={loading} />
      {loan && loan.loanType === "HELOAN" && <HELOANOffer />}
      {loan && loan.loanType === "HELOC" && <HELOCOffer />}
    </Fragment>
  );
};

export default FinalOffer;
