import { Typography, useTheme } from "@mui/material";
import useUser from "context/UserCustomer/useUserCustomerContext";

const TitleMessages = ({ thereIsOffer }: { thereIsOffer: boolean }) => {
  const theme = useTheme();
  const { user } = useUser();

  const offerMessage = `Your Debt to Income has been approved! Please confirm your offer and proceed.`;
  const noOfferMessage = `we are sorry but we are no longer able to extend a HELOC offer at this time based on the data in your application. We apologize for any inconvenience.`;

  return (
    <Typography
      mt={4}
      mb={5}
      variant="h1"
      lineHeight={1}
      color={theme.palette.text.primary}
      letterSpacing={-0.8}
      px={{ xs: 2, md: 0 }}
      fontSize={{ xs: "2rem", md: "3rem" }}
    >
      <span style={{ color: theme.palette.primary.main, marginRight: "10px" }}>
        {thereIsOffer
          ? `Congrats ${user?.firstName}! `
          : `${user?.firstName}, `}
      </span>
      {thereIsOffer ? offerMessage : noOfferMessage}
    </Typography>
  );
};

export default TitleMessages;
