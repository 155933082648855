import API from "utils/API";
import Loan from "typedef/Loan";
import { useState, useEffect } from "react";

export type Marks = Loan["initialOffer"][];

const useTypeOfCreditLine = () => {
  const [loan, setLoan] = useState<Loan>();
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(true);
  const [markSelectIndex, setMarkSelectIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handleRefresh = () => {
    setRefresh((prev) => prev + 1);
  };

  const handleChangeDrawAmount = (value: number) => {
    setLoan((prev) => {
      const previousLoan = prev as Loan;

      return {
        ...previousLoan,
        initialOffer: {
          ...previousLoan?.initialOffer,
          initialDrawAmount: value,
        },
      };
    });
  };

  useEffect(() => {
    const getLoan = async () => {
      setShowErrorMessage(false);
      setLoading(true);
      const loanResponse = await API.get<Loan>(`/get/my-loan`);
      if ("error" in loanResponse) {
        setShowErrorMessage(true);
        setErrorMessage(loanResponse.error);
      } else {
        setLoan(loanResponse.data);
      }
      setLoading(false);
    };

    getLoan();
  }, [refresh]);

  return {
    handleRefresh,
    loan,
    loading,
    errorMessage,
    showErrorMessage,
    markSelectIndex,
    setMarkSelectIndex,
    handleChangeDrawAmount,
  };
};

export default useTypeOfCreditLine;
