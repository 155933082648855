import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import CButton from "components/CButton";
import { FC, useEffect, useState } from "react";
import { HELOCFormProps } from "typedef/HELOCFormProps";
import ShowNetDetailsModal from "./showNetDetailsModal";

const EstNetProceeds: FC<HELOCFormProps> = ({
  netFundingWiredToBankCalculation,
  loan,
  newValuesSlider,
}) => {
  const theme = useTheme();
  const [showDetails, setShowDetails] = useState(false);
  const [totalPayoffs, setTotalPayoffs] = useState(0);
  const [totalPaydowns, setTotalPaydowns] = useState(0);

  useEffect(() => {
    let payoffs = 0;
    let paydowns = 0;

    if (loan?.payoff) {
      loan.payoff.forEach((element) => {
        payoffs += element?.amount ?? 0;
      });
    }

    if (loan?.borrowerSteps?.hardpullVerification?.paydownInfo) {
      loan?.borrowerSteps?.hardpullVerification?.paydownInfo?.forEach(
        (element) => {
          paydowns += element.paydownAmount
            ? parseInt(element?.paydownAmount, 10)
            : 0;
        },
      );
    }

    setTotalPayoffs(payoffs);
    setTotalPaydowns(paydowns);
  }, [loan]);

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 2fr"
      alignItems="center"
      sx={{ backgroundColor: theme.palette.background.paper, p: 2 }}
    >
      <Typography variant="h6">Est. Net Proceeds</Typography>
      <Box display="flex" alignItems="center">
        {netFundingWiredToBankCalculation ? (
          <>
            <Typography
              variant="h5"
              color={theme.palette.text.primary}
              fontFamily={"Pro Display Bold"}
            >
              $
              {Math.floor(
                netFundingWiredToBankCalculation - totalPaydowns - totalPayoffs,
              ).toLocaleString("en-US")}
            </Typography>
            <CButton
              sx={{ minHeight: 0, minWidth: 0, padding: 0, ml: 2 }}
              onClick={() => setShowDetails(true)}
            >
              Show details
            </CButton>
          </>
        ) : (
          <Skeleton width="75%" height={"1.5rem"} />
        )}
      </Box>
      {showDetails && (
        <ShowNetDetailsModal
          openModal={showDetails}
          onClose={() => {
            setShowDetails(false);
          }}
          paydowns={loan?.borrowerSteps?.hardpullVerification?.paydownInfo}
          payoffs={loan?.payoff}
          loan={loan}
        />
      )}
    </Box>
  );
};

export default EstNetProceeds;
