import { FC } from "react";
import CButton from "components/CButton";
import CDialog from "components/CDialog";
import { Close } from "@mui/icons-material";
import CTextField from "components/CTextField";
import useCoborrowerInviteForm from "./useCoborrowerInviteForm";
import {
  Box,
  DialogProps,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";

const CoborrowerInvite: FC<
  DialogProps & {
    onDismiss?(): void;
    customFlags?: Record<string, string | number | boolean>;
  }
> = ({ onDismiss, customFlags, ...props }) => {
  const theme = useTheme();
  const form = useCoborrowerInviteForm(props.onClose, customFlags);
  const isUniqueExtensions = (value: string) => {
    const extensions = value?.match(/\.[a-zA-Z]+/g) || [];
    const uniqueExtensions = Array.from(new Set(extensions));
    return extensions.length === uniqueExtensions.length;
  };

  return (
    <CDialog maxWidth="xs" {...props}>
      <form onSubmit={form.onSubmit}>
        <Box display="flex" alignItems="center">
          <Typography
            mr={15}
            variant="h5"
            fontSize={30}
            textAlign="left"
            fontFamily={"Pro Display Semibold"}
          >
            Add Co-Borrower
          </Typography>
          <IconButton
            onClick={() => {
              if (onDismiss) onDismiss();
              else props.onClose?.({}, "backdropClick");
            }}
          >
            <Close fontSize="large" htmlColor={theme.palette.text.secondary} />
          </IconButton>
        </Box>
        <CTextField
          fullWidth
          sx={{ mt: 4 }}
          label="Co-borrower's First Name"
          error={form.formState.errors.firstName?.message}
          {...form.register("firstName", {
            required: "This field is required",
          })}
        />
        <CTextField
          fullWidth
          sx={{ my: 2 }}
          label="Co-borrower's Last Name"
          error={form.formState.errors.lastName?.message}
          {...form.register("lastName", {
            required: "This field is required",
          })}
        />
        <CTextField
          fullWidth
          error={form.formState.errors.email?.message}
          label="Co-borrower's Email"
          {...form.register("email", {
            required: {
              value: true,
              message: "This field is required",
            },
            pattern: {
              value:
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i,
              message: "The Email format is incorrect.",
            },
            validate: (value) =>
              isUniqueExtensions(value) || "The email format is incorrect.",
          })}
        />
        <CButton
          type="submit"
          loading={form.formState.isSubmitting}
          variant="contained"
          fullWidth
          sx={{ marginTop: 4 }}
          name="coborrowerInviteDialog-save"
        >
          Save
        </CButton>
      </form>
    </CDialog>
  );
};

export default CoborrowerInvite;
