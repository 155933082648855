import API from "utils/API";
import { useState } from "react";
import { useForm } from "react-hook-form";
import useTracker from "components/CTracker/useTrackerContext";
import DemographicsDataChecks from "typedef/DemographicsDataChecks";
import { DemographicDataResponseType } from "typedef/DemographicDataForm";

const useDemographicsData = (onClose: () => void) => {
  const tracker = useTracker();

  const [errorMessage, setErrorMessage] = useState<string>();

  const form = useForm<DemographicsDataChecks>({
    defaultValues: {
      ethnicity: {
        hispanicOrLatino: false,
        mexican: false,
        puertoRican: false,
        cuban: false,
        otherHispanicOrLatino: false,
        customEthnicity: "",
        notHispanicOrLatino: false,
        prefNoSay: false,
      },
      race: {
        americanIndianOrAlaskaNative: false,
        customAmericanIndianOrAlaskan: "",
        asian: false,
        asianIndian: false,
        chinese: false,
        filipino: false,
        japanese: false,
        korean: false,
        vietnamese: false,
        otherAsian: false,
        customOtherAsian: "",
        blackOrAfricanAmerican: false,
        nativeHawaiian: false,
        guamanianOrChamorro: false,
        samoan: false,
        otherPacificIslander: false,
        customOtherPacificIslander: "",
        nativeHawaiianOrOtherPacificIslander: false,
        white: false,
        prefNoSay: false,
      },
      sex: {
        male: false,
        female: false,
        prefNoSay: false,
      },
      citizenship: "usCitizen",
    },
  });

  const onSubmit = form.handleSubmit(async (data) => {
    if (checkAnswer(data)) {
      setErrorMessage(undefined);
      if (!data.ethnicity.otherHispanicOrLatino) {
        form.setValue("ethnicity.customEthnicity", "");
      }
      if (!data.race.americanIndianOrAlaskaNative) {
        form.setValue("race.customAmericanIndianOrAlaskan", "");
      }
      if (!data.race.otherAsian) {
        form.setValue("race.customOtherAsian", "");
      }
      if (!data.race.otherPacificIslander) {
        form.setValue("race.customOtherPacificIslander", "");
      }

      try {
        const response = await API.post<DemographicDataResponseType>({
          url: `/save-to-loan/demographics`,
          data: {
            loanId: tracker.loan?.id,
            ethnicity: {
              hispanicOrLatino: data.ethnicity.hispanicOrLatino,
              mexican: data.ethnicity.mexican,
              puertoRican: data.ethnicity.puertoRican,
              cuban: data.ethnicity.cuban,
              otherHispanicOrLatino: data.ethnicity.otherHispanicOrLatino,
              customEthnicity: data.ethnicity.customEthnicity,
              notHispanicOrLatino: data.ethnicity.notHispanicOrLatino,
              prefNoSay: data.ethnicity.prefNoSay,
            },
            race: {
              americanIndianOrAlaskaNative:
                data.race.americanIndianOrAlaskaNative,
              customAmericanIndianOrAlaskan:
                data.race.customAmericanIndianOrAlaskan,
              asian: data.race.asian,
              asianIndian: data.race.asianIndian,
              chinese: data.race.chinese,
              filipino: data.race.filipino,
              japanese: data.race.japanese,
              korean: data.race.korean,
              vietnamese: data.race.vietnamese,
              otherAsian: data.race.otherAsian,
              customOtherAsian: data.race.customOtherAsian,
              blackOrAfricanAmerican: data.race.blackOrAfricanAmerican,
              nativeHawaiian: data.race.nativeHawaiian,
              guamanianOrChamorro: data.race.guamanianOrChamorro,
              samoan: data.race.samoan,
              otherPacificIslander: data.race.otherPacificIslander,
              customOtherPacificIslander: data.race.customOtherPacificIslander,
              nativeHawaiianOrOtherPacificIslander:
                data.race.nativeHawaiianOrOtherPacificIslander,
              white: data.race.white,
              prefNoSay: data.race.prefNoSay,
            },
            sex: {
              male: data.sex.male,
              female: data.sex.female,
              prefNoSay: data.sex.prefNoSay,
            },
            citizenship: data.citizenship,
          },
        });
        if ("error" in response) {
          setErrorMessage(response.error);
        } else {
          onClose();
        }
      } catch (unknownError) {
        setErrorMessage(String(unknownError));
        console.error(unknownError);
      }
    } else {
      setErrorMessage("Please answer all questions");
    }
  });

  return {
    onSubmit,
    errorMessage,
    setErrorMessage,
    ...form,
  };
};

export default useDemographicsData;

function checkAnswer(data: DemographicsDataChecks) {
  return (
    checkSex(data) &&
    checkEthnicity(data) &&
    checkRace(data) &&
    checkCitizenship(data)
  );
}

function checkSex(data: DemographicsDataChecks) {
  return data.sex.female || data.sex.male || data.sex.prefNoSay;
}

function checkEthnicity(data: DemographicsDataChecks) {
  return (
    data.ethnicity.prefNoSay ||
    data.ethnicity.notHispanicOrLatino ||
    data.ethnicity.hispanicOrLatino ||
    data.ethnicity.cuban ||
    data.ethnicity.mexican ||
    data.ethnicity.otherHispanicOrLatino ||
    data.ethnicity.puertoRican
  );
}

function checkCitizenship(data: DemographicsDataChecks) {
  return data.citizenship;
}

function checkRace(data: DemographicsDataChecks) {
  return (
    data.race.prefNoSay ||
    data.race.white ||
    data.race.blackOrAfricanAmerican ||
    data.race.americanIndianOrAlaskaNative ||
    data.race.nativeHawaiianOrOtherPacificIslander ||
    data.race.nativeHawaiian ||
    data.race.guamanianOrChamorro ||
    data.race.samoan ||
    data.race.otherPacificIslander ||
    data.race.asian ||
    data.race.asianIndian ||
    data.race.chinese ||
    data.race.filipino ||
    data.race.japanese ||
    data.race.korean ||
    data.race.vietnamese ||
    data.race.otherAsian
  );
}
