import {
  Box,
  Checkbox,
  FormGroup,
  Typography,
  FormControlLabel,
  useTheme,
  IconButton,
  Collapse,
} from "@mui/material";
import { FC, Fragment, useCallback, useState } from "react";
import CTextField from "components/CTextField";
import { UseFormReturn } from "react-hook-form";
import DemographicsDataChecks from "typedef/DemographicsDataChecks";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

type InnerFormProps = UseFormReturn<DemographicsDataChecks, unknown>;

const RaceOptions: FC<{ form: InnerFormProps }> = ({ form }) => {
  const theme = useTheme();
  const [isExpandedAsian, setIsExpandedAsian] = useState(false);
  const [isExpandedNative, setIsExpandedNative] = useState(false);

  const handleExpandClickAsian = useCallback(() => {
    setIsExpandedAsian((prev) => !prev);
  }, []);
  const handleExpandClickNative = useCallback(() => {
    setIsExpandedNative((prev) => !prev);
  }, []);

  const handleCheckboxChange = useCallback(
    (field: keyof DemographicsDataChecks["race"]) => () => {
      form.setValue(`race.${field}`, !form.getValues(`race.${field}`), {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [form],
  );

  return (
    <Fragment>
      <Box
        mt={2}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h5" color={theme.palette.text.primary}>
          Race
        </Typography>
        <Typography variant="subtitle2" color={theme.palette.text.secondary}>
          Check one or more
        </Typography>
      </Box>
      <Box>
        <FormGroup>
          <FormControlLabel
            label="American Indian or Alaska Native"
            control={
              <Checkbox
                checked={form.getValues().race.americanIndianOrAlaskaNative}
                onChange={handleCheckboxChange("americanIndianOrAlaskaNative")}
              />
            }
          />

          {form.watch("race.americanIndianOrAlaskaNative") && (
            <Fragment>
              <Typography
                variant="subtitle2"
                textAlign={"initial"}
                sx={{ color: theme.palette.text.secondary }}
              >
                Type name of enrolled or principal tribe:
              </Typography>
              <CTextField
                error={
                  form.formState.errors.race?.customAmericanIndianOrAlaskan
                    ?.message
                }
                fullWidth
                type="text"
                placeholder="Type race"
                {...form.register("race.customAmericanIndianOrAlaskan", {
                  required: {
                    value: true,
                    message: "American Indian or Alaska Native is required.",
                  },
                  minLength: {
                    value: 2,
                    message:
                      "The American Indian or Alaska Native must be at least 2 characters long.",
                  },
                  maxLength: {
                    value: 120,
                    message:
                      "The American Indian or Alaska Native cannot be longer than 120 characters.",
                  },
                  pattern: {
                    value:
                      /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<,>;:[\]]{2,}$/,
                    message:
                      "The American Indian or Alaska Native format is incorrect.",
                  },
                })}
              />
            </Fragment>
          )}
          <Box display="flex" alignItems="center">
            <FormControlLabel
              label="Asian"
              control={
                <Checkbox
                  checked={form.getValues().race.asian}
                  onChange={handleCheckboxChange("asian")}
                />
              }
            />
            <IconButton
              onClick={handleExpandClickAsian}
              sx={{
                padding: 0,
                borderRadius: "50%",
                backgroundColor: theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
                color: theme.palette.primary.contrastText,
                marginLeft: 2,
              }}
            >
              {isExpandedAsian ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
          <Collapse in={isExpandedAsian} timeout="auto" unmountOnExit>
            <Box pl={3}>
              <FormGroup>
                <FormControlLabel
                  label="Asian Indian"
                  control={
                    <Checkbox
                      checked={form.getValues().race.asianIndian}
                      onChange={handleCheckboxChange("asianIndian")}
                    />
                  }
                />
                <FormControlLabel
                  label="Chinese"
                  control={
                    <Checkbox
                      checked={form.getValues().race.chinese}
                      onChange={handleCheckboxChange("chinese")}
                    />
                  }
                />
                <FormControlLabel
                  label="Filipino"
                  control={
                    <Checkbox
                      checked={form.getValues().race.filipino}
                      onChange={handleCheckboxChange("filipino")}
                    />
                  }
                />
                <FormControlLabel
                  label="Japanese"
                  control={
                    <Checkbox
                      checked={form.getValues().race.japanese}
                      onChange={handleCheckboxChange("japanese")}
                    />
                  }
                />
                <FormControlLabel
                  label="Korean"
                  control={
                    <Checkbox
                      checked={form.getValues().race.korean}
                      onChange={handleCheckboxChange("korean")}
                    />
                  }
                />
                <FormControlLabel
                  label="Vietnamese"
                  control={
                    <Checkbox
                      checked={form.getValues().race.vietnamese}
                      onChange={handleCheckboxChange("vietnamese")}
                    />
                  }
                />
                <FormControlLabel
                  label="Other Asian"
                  control={
                    <Checkbox
                      checked={form.getValues().race.otherAsian}
                      onChange={handleCheckboxChange("otherAsian")}
                    />
                  }
                />
              </FormGroup>
              {form.watch("race.otherAsian") && (
                <Fragment>
                  <Typography
                    variant="subtitle2"
                    textAlign={"initial"}
                    sx={{ color: theme.palette.text.secondary }}
                  >
                    Type race, for example, Hmong, Laotian, Thai, Pakistani,
                    Cambodian, and so on:
                  </Typography>
                  <CTextField
                    error={
                      form.formState.errors.race?.customOtherAsian?.message
                    }
                    fullWidth
                    type="text"
                    placeholder="Type race"
                    {...form.register("race.customOtherAsian", {
                      required: {
                        value: true,
                        message: "Other Asian is required.",
                      },
                      minLength: {
                        value: 2,
                        message:
                          "The Other Asian must be at least 2 characters long.",
                      },
                      maxLength: {
                        value: 120,
                        message:
                          "The Other Asian cannot be longer than 120 characters.",
                      },
                      pattern: {
                        value:
                          /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<,>;:[\]]{2,}$/,
                        message: "The Other Asian format is incorrect.",
                      },
                    })}
                  />
                </Fragment>
              )}
            </Box>
          </Collapse>
          <FormControlLabel
            label="Black or African American"
            control={
              <Checkbox
                checked={form.getValues().race.blackOrAfricanAmerican}
                onChange={handleCheckboxChange("blackOrAfricanAmerican")}
              />
            }
          />
          <Box display="flex" alignItems="center">
            <FormControlLabel
              label="Native Hawaiian or Other Pacific Islander"
              control={
                <Checkbox
                  checked={
                    form.getValues().race.nativeHawaiianOrOtherPacificIslander
                  }
                  onChange={handleCheckboxChange(
                    "nativeHawaiianOrOtherPacificIslander",
                  )}
                />
              }
            />
            <IconButton
              onClick={handleExpandClickNative}
              sx={{
                padding: 0,
                borderRadius: "50%",
                backgroundColor: theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
                color: theme.palette.primary.contrastText,
                marginLeft: 2,
              }}
            >
              {isExpandedNative ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>

          <Collapse in={isExpandedNative} timeout="auto" unmountOnExit>
            <Box pl={3}>
              <FormGroup>
                <FormControlLabel
                  label="Native Hawaiian"
                  control={
                    <Checkbox
                      checked={form.getValues().race.nativeHawaiian}
                      onChange={handleCheckboxChange("nativeHawaiian")}
                    />
                  }
                />
                <FormControlLabel
                  label="Guamanian or Chamorro"
                  control={
                    <Checkbox
                      checked={form.getValues().race.guamanianOrChamorro}
                      onChange={handleCheckboxChange("guamanianOrChamorro")}
                    />
                  }
                />
                <FormControlLabel
                  label="Samoan"
                  control={
                    <Checkbox
                      checked={form.getValues().race.samoan}
                      onChange={handleCheckboxChange("samoan")}
                    />
                  }
                />
                <FormControlLabel
                  label="Other Pacific Islander"
                  control={
                    <Checkbox
                      checked={form.getValues().race.otherPacificIslander}
                      onChange={handleCheckboxChange("otherPacificIslander")}
                    />
                  }
                />
              </FormGroup>
              {form.watch("race.otherPacificIslander") && (
                <Fragment>
                  <Typography
                    variant="subtitle2"
                    textAlign={"initial"}
                    sx={{ color: theme.palette.text.secondary }}
                  >
                    Type race, for example, Fijian, Tongan, and so on:
                  </Typography>
                  <CTextField
                    error={
                      form.formState.errors.race?.customOtherPacificIslander
                        ?.message
                    }
                    fullWidth
                    type="text"
                    placeholder="Type race"
                    {...form.register("race.customOtherPacificIslander", {
                      required: {
                        value: true,
                        message: "Other Pacific Islander is required.",
                      },
                      minLength: {
                        value: 2,
                        message:
                          "The Other Pacific Islander must be at least 2 characters long.",
                      },
                      maxLength: {
                        value: 120,
                        message:
                          "The Other Pacific Islander cannot be longer than 120 characters.",
                      },
                      pattern: {
                        value:
                          /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<,>;:[\]]{2,}$/,
                        message:
                          "The Other Pacific Islander format is incorrect.",
                      },
                    })}
                  />
                </Fragment>
              )}
            </Box>
          </Collapse>
          <FormControlLabel
            label="White"
            control={
              <Checkbox
                checked={form.getValues().race.white}
                onChange={handleCheckboxChange("white")}
              />
            }
          />
          <FormControlLabel
            label="I do not wish to provide this Information"
            control={
              <Checkbox
                checked={form.getValues().race.prefNoSay}
                onChange={handleCheckboxChange("prefNoSay")}
              />
            }
          />
        </FormGroup>
      </Box>
    </Fragment>
  );
};
export default RaceOptions;
