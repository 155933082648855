import Typography from "@mui/material/Typography";
import CTrackerTimelineItem from "components/CTimelineItem";
import useTracker from "components/CTracker/useTrackerContext";
import { TrackerStatusColor } from "typedef/TrackerStatusColor";

const HomeValueVerification: React.FC<{
  trackerStatusColor: TrackerStatusColor;
}> = ({ trackerStatusColor }) => {
  const { loan } = useTracker();
  const borrowerSteps = loan?.borrowerSteps;
  const prevStep =
    borrowerSteps?.creditVerification.status === "success" ||
    (borrowerSteps?.creditVerification.status === "analyzing" &&
      (borrowerSteps?.creditVerification.needDocReview !== undefined ||
        borrowerSteps?.creditVerification.deletedDocRequest));
  const status = prevStep
    ? borrowerSteps?.homeValuationVerification?.status ?? "pending"
    : "pending";
  const message = borrowerSteps?.homeValuationVerification?.message;

  const needAppraisal =
    loan?.borrowerSteps?.appraisalVerification?.status &&
    loan?.borrowerSteps?.appraisalVerification?.status === "analyzing";

  const processingStatus =
    loan?.borrowerSteps?.homeValuationVerification?.processingStatus;

  return (
    <CTrackerTimelineItem
      processingStatus={processingStatus}
      status={needAppraisal && status !== "pending" ? "analyzing" : status}
      title="Home Valuation"
      tooltip="Your value is determined by an Automated Valuation Model or AVM, through a third party appraisal management company. Oftentimes, a full appraisal is not required."
    >
      <Typography
        variant="h6"
        color={[trackerStatusColor[needAppraisal ? "analyzing" : status]]}
      >
        {status === "analyzing" &&
          (borrowerSteps?.homeValuationVerification.messageToBorrower ??
            message ??
            "Analyzing")}
        {status === "failed" && message}
        {status === "success" && message}
      </Typography>
    </CTrackerTimelineItem>
  );
};

export default HomeValueVerification;
