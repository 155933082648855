import { useState } from "react";
import CAvatar from "components/CAvatar";
import { useParams } from "react-router-dom";
import CCustomLogo from "components/CCustomLogo";
import { Turn as Hamburger } from "hamburger-react";
import useInvite from "context/Invite/useInviteContext";
import CLoginButton from "./CButtonsHeader/CLoginButton";
import useTracker from "components/CTracker/useTrackerContext";
import {
  Box,
  Drawer,
  Toolbar,
  Container,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CContactusButton from "./CButtonsHeader/CContactusButton";
import useUser from "context/UserCustomer/useUserCustomerContext";
import CGetintouchButton from "./CButtonsHeader/CGetintouchButton";
import CForlendersButton from "./CButtonsHeader/CForlendersButton";
import CContactusGladlyButton from "./CButtonsHeader/CContactusGladlyButton";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import FortunaLogo from "assets/Images/Fortuna-logo.png";
import LaduLogo from "assets/Images/Ladu-logo.png";

const CHeader = () => {
  const [opened, setOpened] = useState(false);
  // eslint-disable-next-line
  const isScreenWiderThanMD = useMediaQuery((t: any) => t.breakpoints.up("md"));
  const { user } = useUser();
  const invite = useInvite();
  const params = useParams();
  const { loan } = useTracker();
  const sourceId = params["sourceId"];
  const theme = useTheme();
  const { privateLabel } = usePrivateLabel();

  const logo = invite?.broker?.logo
    ? invite?.broker?.logo
    : loan?.broker?.logo
      ? loan?.broker?.logo
      : sourceId === "fortuna"
        ? "fortuna"
        : sourceId === "ladu"
          ? "ladu"
          : privateLabel
            ? privateLabel.logo
            : "NFTYDoor";

  const brokerHasLogo = invite?.broker?.logo || loan?.broker?.logo;
  const link = brokerHasLogo && invite?.broker?.web ? invite?.broker?.web : "/";

  const isLogoClickable = () => {
    if (brokerHasLogo && !!(link === "/")) {
      return false;
    }
    return true;
  };

  const pathname = window.location.pathname;

  const isLongHeader =
    pathname === "/" ||
    pathname === "/faqs" ||
    pathname === "/lenders" ||
    pathname === "*";

  const isLender = pathname === "/lenders";
  const isLogin = pathname === "/login";

  const isSignupForm =
    pathname.slice(pathname.length - 6, pathname.length) === "signup";

  const isTracker =
    pathname === "/borrower-tracker" || pathname === "/coborrower-tracker";

  const wasSubmitted = pathname === "/submitted";

  return (
    <Box
      top={0}
      zIndex={100}
      position="sticky"
      display={"flex"}
      justifyContent={"space-between"}
      borderBottom={"1px solid #31313127"}
      bgcolor={
        isLongHeader
          ? theme.palette.background.paper
          : theme.palette.background.default
      }
    >
      <Container
        maxWidth={isLongHeader ? "lg" : "md"}
        sx={{
          height: "90px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Toolbar disableGutters>
          <Box
            flexGrow={1}
            display={{ xs: "flex", md: "none" }}
            {...(isScreenWiderThanMD && {
              "aria-hidden": "true",
              "data-acsb-hidden": "true",
            })}
          >
            <Box>
              {logo === "fortuna" ? (
                <CCustomLogo
                  handleClick={() => setOpened(false)}
                  link={"/"}
                  logo={FortunaLogo}
                />
              ) : logo === "ladu" ? (
                <CCustomLogo
                  handleClick={() => setOpened(false)}
                  link={"/"}
                  logo={LaduLogo}
                />
              ) : (
                <CCustomLogo
                  handleClick={() => setOpened(false)}
                  link={link}
                  logo={logo}
                  isLogoClickable={isLogoClickable()}
                />
              )}
            </Box>
            <Drawer
              open={opened}
              anchor="left"
              variant="persistent"
              sx={{
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  top: "90px",
                  width: "100%",
                  boxSizing: "border-box",
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            >
              <Box
                px={3}
                pt={3}
                display="flex"
                alignItems={{ md: "center", xs: "start" }}
                flexDirection={{ xs: "column", md: "row" }}
              >
                {" "}
                {!isTracker ? (
                  !(isSignupForm && invite) && (
                    <CContactusButton
                      handleClick={() => setOpened(false)}
                      pathname={pathname}
                      contactUsLink={privateLabel?.contactUsLink}
                    />
                  )
                ) : (
                  <CContactusGladlyButton
                    handleClick={() => setOpened(false)}
                  />
                )}
                {isLogin && !user && (
                  <CGetintouchButton handleClick={() => setOpened(false)} />
                )}
                {((!isLogin &&
                  !user &&
                  !isSignupForm &&
                  !isLender &&
                  !wasSubmitted) ||
                  (isSignupForm && !invite)) && (
                  <CLoginButton handleClick={() => setOpened(false)} />
                )}
                {privateLabel?.isNFTYDoor &&
                  !isLogin &&
                  !user &&
                  !isLender &&
                  !isSignupForm &&
                  !wasSubmitted && (
                    <CForlendersButton
                      handleClick={() => setOpened(false)}
                      isLongHeader={isLongHeader}
                    />
                  )}
                <Box
                  sx={{ display: { xs: "none", md: "flex" } }}
                  {...(!user?.id && {
                    "aria-hidden": "true",
                    "data-acsb-hidden": "true",
                  })}
                >
                  <CAvatar />
                </Box>
              </Box>
            </Drawer>
          </Box>
        </Toolbar>

        <Box
          alignItems={"center"}
          display={{ xs: "flex", md: "none" }}
          data-acsb-clickable={true}
          data-acsb-navigable={true}
          data-acsb-now-navigable={false}
          sx={{ WebkitTapHighlightColor: "transparent" }}
          {...(isScreenWiderThanMD && {
            "aria-hidden": "true",
            "data-acsb-hidden": "true",
          })}
        >
          <Box
            mr={4}
            {...(!user?.id && {
              "aria-hidden": "true",
              "data-acsb-hidden": "true",
            })}
          >
            <CAvatar />
          </Box>
          <Box m={-1}>
            <Hamburger
              size={30}
              toggled={opened}
              toggle={setOpened}
              color={theme.palette.primary.main}
            />
          </Box>
        </Box>

        <Box
          flexGrow={1}
          alignItems={"center"}
          justifyContent={"space-between"}
          display={{ xs: "none", md: "flex" }}
          {...(!isScreenWiderThanMD && {
            "aria-hidden": "true",
            "data-acsb-hidden": "true",
          })}
        >
          {logo === "fortuna" ? (
            <CCustomLogo
              handleClick={() => setOpened(false)}
              link={"/"}
              logo={FortunaLogo}
            />
          ) : logo === "ladu" ? (
            <CCustomLogo
              handleClick={() => setOpened(false)}
              link={"/"}
              logo={LaduLogo}
            />
          ) : (
            <CCustomLogo
              handleClick={() => setOpened(false)}
              link={link}
              logo={logo}
              isLogoClickable={isLogoClickable()}
            />
          )}
          <Box
            display="flex"
            alignItems={{ md: "center", xs: "start" }}
            flexDirection={{ xs: "column", md: "row" }}
          >
            {!isTracker ? (
              !(isSignupForm && invite) && (
                <CContactusButton
                  pathname={pathname}
                  contactUsLink={privateLabel?.contactUsLink}
                />
              )
            ) : (
              <CContactusGladlyButton handleClick={() => setOpened(false)} />
            )}
            {isLogin && !user && (
              <CGetintouchButton handleClick={() => setOpened(false)} />
            )}
            {((!isLogin &&
              !user &&
              !isSignupForm &&
              !isLender &&
              !wasSubmitted) ||
              (isSignupForm && !invite)) && (
              <CLoginButton handleClick={() => setOpened(false)} />
            )}
            {privateLabel?.isNFTYDoor &&
              !isLogin &&
              !user &&
              !isLender &&
              !isSignupForm &&
              !wasSubmitted && (
                <CForlendersButton
                  handleClick={() => setOpened(false)}
                  isLongHeader={isLongHeader}
                />
              )}
            <Box
              sx={{ display: { xs: "none", md: "flex" } }}
              {...(!user?.id && {
                "aria-hidden": "true",
                "data-acsb-hidden": "true",
              })}
            >
              <CAvatar />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default CHeader;
