import { useTheme } from "@mui/material";
import CButton from "components/CButton";

const CContactusGladlyButton: React.FC<{ handleClick: () => void }> = ({
  handleClick,
}) => {
  const theme = useTheme();
  return (
    <CButton
      variant="text"
      onClick={() => {
        handleClick();
        Gladly.show();
      }}
      sx={{
        fontSize: { md: "1.4rem", xs: "2.4rem" },
        fontFamily: "Pro Display Regular",
        color: {
          md: theme.palette.text.primary,
          xs: theme.palette.background.paper,
        },
        overflow: "none",
        ":hover": {
          backgroundColor: {
            md: theme.palette.background.default,
            xs: theme.palette.primary.main,
          },
        },
        padding: 0,
      }}
      disableRipple
    >
      Contact us
    </CButton>
  );
};

export default CContactusGladlyButton;
