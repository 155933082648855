import CButton from "components/CButton";

const CGetintouchButton: React.FC<{ handleClick: () => void }> = ({
  handleClick,
}) => {
  return (
    <CButton
      onClick={handleClick}
      href="mailto:info@nftydoor.com?Subject=info@nftydoor.com"
      sx={{
        maxWidth: "240px",
        marginTop: { md: 0, xs: 2 },
        marginLeft: { md: 2, xs: 0 },
        border: { xs: "solid 1px white", md: "none" },
      }}
      variant="contained"
    >
      Get in touch
    </CButton>
  );
};

export default CGetintouchButton;
