import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import CButton from "components/CButton";
import { FC, useState } from "react";
import EditLoanAmount from "screens/TypeOfCreditLine/EditLoanAmount";
import { HELOCFormProps } from "typedef/HELOCFormProps";

const LoanRequest: FC<HELOCFormProps> = ({ handleRrefresh, loan }) => {
  const theme = useTheme();
  const [editLoanAmountOpen, setEditLoanAmountOpen] = useState(false);
  const property = loan?.property;
  let bpoPending = false;

  if (
    loan?.borrowerSteps?.appraisalVerification?.appraisalType === "BPO" &&
    loan?.borrowerSteps?.appraisalVerification?.status === "analyzing"
  ) {
    bpoPending = true;
  }

  return (
    <>
      <Box
        display="grid"
        gridTemplateColumns="1fr 2fr"
        alignItems="center"
        sx={{ backgroundColor: theme.palette.background.paper, p: 2 }}
      >
        <Typography variant="h6" fontFamily="Pro Display Regular">
          Loan Request
        </Typography>
        {loan?.finalOffer?.amount ? (
          <Box display="flex" alignItems="center">
            <Typography variant="h5" fontFamily="Pro Display Bold">
              ${(loan?.finalOffer?.amount ?? 0).toLocaleString("en-US")}
            </Typography>
            {!bpoPending && (
              <CButton
                sx={{ minHeight: 0, minWidth: 0, padding: 0, ml: 1 }}
                onClick={() => setEditLoanAmountOpen(true)}
              >
                Edit
              </CButton>
            )}
          </Box>
        ) : (
          <Skeleton width="75%" height={"1.5rem"} />
        )}
      </Box>

      <EditLoanAmount
        endpoint="/change-offer-amount/final"
        open={editLoanAmountOpen}
        tnMaxOffer={
          // temporary TN validation
          loan?.initialOffer.tnMaxOffer
            ? loan?.initialOffer.tnMaxOffer
            : undefined
        }
        loanId={loan?.id}
        onSubmit={() => {
          if (handleRrefresh) {
            handleRrefresh();
          }
        }}
        onClose={() => {
          setEditLoanAmountOpen(false);
        }}
        state={property?.address?.components?.state_abbreviation}
        setEditLoanAmountOpen={setEditLoanAmountOpen}
      />
    </>
  );
};

export default LoanRequest;
