import * as yup from "yup";
import API from "utils/API";
import { useState } from "react";
import useLoan from "../useLoan";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useHomeMonitor from "../useHomeMonitorContext";
import { DocumentsHook } from "../Documents/useDocuments";

const usePayoff = () => {
  const { loan } = useLoan();
  const [loading, setLoading] = useState(false);
  const homeMonitorContext = useHomeMonitor();
  const documentsHook = homeMonitorContext.documentsHook as DocumentsHook;

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .required("First name is required.")
      .max(35, "First Name must be at most 35 characters"),
    lastName: yup
      .string()
      .required("Last name is required.")
      .max(35, "Last Name must be at most 35 characters"),
    companyName: yup.string(),
    email: yup.string().email("Email has invalid format."),
  });

  const form = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const [error, setError] = useState<string>();
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    form.reset();
    form.clearErrors();
    setOpenModal(false);
  };
  const clearError = () => {
    setError(undefined);
  };

  const onSubmit = form.handleSubmit(async (data) => {
    setLoading(true);
    const response = await API.post({
      url: `/save-payoff-data`,
      data: { ...data, loanId: loan?.id },
    });
    if ("error" in response) {
      setError(response.error);
    } else {
      handleCloseModal();
      form.reset();
      documentsHook.setRefetch((prev) => !prev);
    }
    setLoading(false);
  });

  return {
    form,
    error,
    loading,
    onSubmit,
    openModal,
    clearError,
    handleOpenModal,
    handleCloseModal,
  };
};

export default usePayoff;
