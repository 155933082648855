import API from "utils/API";
import Loan from "typedef/Loan";
import { useCallback, useEffect, useState } from "react";
import useUser from "context/UserCustomer/useUserCustomerContext";
import { errorMessages } from "utils/errorMessages";

const useLoan = () => {
  const [loan, setLoan] = useState<Loan>();
  const { user, logout } = useUser();

  const getAndSetCoborrowerLoan = useCallback(async () => {
    if (user) {
      const response = await API.get<Loan>(`/get/my-loan`);
      if ("error" in response) {
        console.error(response.error);
      } else {
        const isBorrower = user?.id === response?.data?.borrowerId;
        const stepName = isBorrower ? "borrowerSteps" : "coborrowerSteps";
        const currentStep =
          response?.data?.[stepName]?.creditVerification?.adminError;
        if (
          response?.data?.loanStatusCode === "ARCHIVED" &&
          currentStep !== errorMessages.creditVerification.adminError
        ) {
          logout();
        }
        setLoan(response.data);
      }
    }
  }, [user, logout]);

  useEffect(() => {
    getAndSetCoborrowerLoan();
    const timeoutId = setInterval(getAndSetCoborrowerLoan, 2000);
    return () => {
      clearInterval(timeoutId);
    };
  }, [getAndSetCoborrowerLoan]);

  return loan;
};

export default useLoan;
