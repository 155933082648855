import { Fragment, useState, useEffect } from "react";
import {
  Box,
  useTheme,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CLoader from "components/CLoader";
import EditInitialLoanAmount from "./EditInitialLoanAmount";
import useTypeOfCreditLine from "./useTypeOfCreditLine";
import useUser from "context/UserCustomer/useUserCustomerContext";
import ConfirmTXNotHomestead from "components/CTracker/StepsDialogs/ConfirmTXNotHomestead";
import { useOffers } from "screens/InitialOffer/useOffers";
import Loan from "typedef/Loan";
import dollarFormatter from "utils/FormatterDollar";
import toPercentage from "utils/toPercentage";
import OfferOptionsByFico from "./OfferOptionsByFico";
import AcceptOfferButton from "./AcceptOfferButton";
import WarningMessage from "./WarningMessage";
import CButton from "components/CButton";
import DrawRequestModal from "./DrawRequestModal";
import WarningIcon from "@mui/icons-material/Warning";
import useDefineLoanType from "./useDefineLoanType";
import API from "utils/API";
import FeesModal from "./FeesModal";
import useInitialHelocOffer from "./useInitialHelocOffer";

const PERCENTAGE = 100;
const AMOUNT_OF_DECIMALS = 2;

const TypeOfCreditLine: React.FC = () => {
  const typeOfCreditLine = useTypeOfCreditLine();
  const theme = useTheme();
  const loanType = useDefineLoanType(typeOfCreditLine?.loan as Loan);
  const { user, logout } = useUser();
  const loan = typeOfCreditLine.loan;
  const offer = useInitialHelocOffer();
  const { offertOptionsByFico } = useOffers(typeOfCreditLine?.loan as Loan);

  const [isLoanAmountModalOpen, setIsLoanAmountModalOpen] = useState(false);
  const [isDrawRequestModalOpen, setIsDrawRequestModalOpen] = useState(false);
  const [isFeesModalOpen, setIsFeesModalOpen] = useState(false);
  const [isLoadingOffer, setIsLoadingOffer] = useState(false);
  const [hasError, setHasError] = useState(false);

  const property = typeOfCreditLine?.loan?.property;
  const txNotAHomestead =
    typeOfCreditLine?.loan?.borrowerFlags?.txHomestead === false;
  const loanNotArchived = !typeOfCreditLine?.loan?.borrowerFlags?.archiveType;
  const [openModalConfirmTx, setOpenModalConfirmTx] = useState(txNotAHomestead);
  let bpoPending = false;

  if (
    typeOfCreditLine?.loan?.borrowerSteps?.appraisalVerification &&
    typeOfCreditLine?.loan?.borrowerFlags?.enableAppraisal
  ) {
    bpoPending = true;
  }

  useEffect(() => {
    setOpenModalConfirmTx(txNotAHomestead);
  }, [txNotAHomestead]);

  const handleSubmit = async (amount: number, loanId: string) => {
    try {
      setHasError(false);
      setIsLoadingOffer(true);

      const response = await API.post({
        url: "/change-offer-amount/initial",
        method: "PUT",
        data: {
          amount,
          loanId,
        },
      });

      if ("error" in response) {
        setHasError(true);
      } else {
        typeOfCreditLine.handleRefresh();
        offer.handleRrefresh();
      }
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoadingOffer(false);
    }
  };

  const isFirstLien =
    typeOfCreditLine?.loan?.initialOffer.currentLoanBalance === 0 &&
    typeOfCreditLine?.loan.occupancy === "Primary Residence";
  //temporary TN validation
  const tnMaxOffer =
    typeOfCreditLine?.loan?.property?.address?.components.state_abbreviation ===
    "TN"
      ? Math.floor(typeOfCreditLine?.loan?.initialOffer?.tnMaxOffer ?? 0)
      : undefined;

  const parsedLoanAmount = dollarFormatter.format(
    typeOfCreditLine?.loan?.initialOffer?.amount ?? 0,
  );

  const parsedInterestRate = toPercentage(
    typeOfCreditLine?.loan?.initialOffer?.interestRate ?? 0,
  );

  const parsedMonthlyPayment = dollarFormatter.format(
    typeOfCreditLine?.loan?.initialOffer?.monthlyPayment ?? 0,
  );

  const drawPercentageNumber =
    ((typeOfCreditLine?.loan?.initialOffer?.initialDrawAmount ?? 0) *
      PERCENTAGE) /
    (typeOfCreditLine?.loan?.initialOffer?.amount ?? 0);
  const drawPercentage =
    drawPercentageNumber.toString().includes(".") &&
    drawPercentageNumber.toString().split(".")[1].length > AMOUNT_OF_DECIMALS
      ? drawPercentageNumber.toFixed(AMOUNT_OF_DECIMALS)
      : drawPercentageNumber;

  const netFundingWiredToBankCalculation =
    (typeOfCreditLine?.loan?.initialOffer?.initialDrawAmount ?? 0) -
    (typeOfCreditLine?.loan?.initialOffer?.initialDrawAmount ?? 0) * 0.0299 -
    (typeOfCreditLine?.loan?.loanFees?.creditReport ?? 0) -
    (typeOfCreditLine?.loan?.loanFees?.complianceDocPrepeNote ?? 0) -
    (typeOfCreditLine?.loan?.loanFees?.AVMInspection ?? 0) -
    (typeOfCreditLine?.loan?.loanFees?.IDVerification ?? 0) -
    (typeOfCreditLine?.loan?.loanFees?.valuesInNotary?.recordingFeeSimplifile ??
      0) -
    (typeOfCreditLine?.loan?.loanFees?.legalVestingFee ?? 65) -
    (typeOfCreditLine?.loan?.borrowerSteps?.titleVerification
      ?.recordingFeesAndOtherTaxes ?? 0);
  const parsedNetFundingWiredToBank = dollarFormatter.format(
    netFundingWiredToBankCalculation,
  );

  const estPointsAndFees =
    (typeOfCreditLine?.loan?.initialOffer?.initialDrawAmount ?? 0) -
    (netFundingWiredToBankCalculation ?? 0);
  const parsedEstPointsAndFees = dollarFormatter.format(estPointsAndFees);

  if (hasError) {
    return (
      <Container
        maxWidth="md"
        //eslint-disable-next-line
        disableGutters={useMediaQuery(theme.breakpoints.only("xs"))}
      >
        <Box>
          <Typography
            mt={4}
            mb={5}
            variant="h1"
            lineHeight={1}
            color={theme.palette.text.primary}
            letterSpacing={-0.8}
            px={{ xs: 2, md: 0 }}
            fontSize={{ xs: "2rem", md: "3rem" }}
          >
            <span
              style={{ color: theme.palette.primary.main, marginRight: "10px" }}
            >
              {user?.firstName},{" "}
            </span>{" "}
            we are so sorry but we are no longer able to extend a HELOC offer at
            this time based on the data in your application. We apologize for
            any inconvenience.
          </Typography>
        </Box>
        <Box
          bgcolor={theme.palette.background.paper}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "36px",
            borderTopLeftRadius: "33px",
            borderTopRightRadius: "33px",
          }}
          py={"40px"}
          px={"70px"}
          mb="6px"
        >
          <Typography fontSize="25px">
            As things often change with time, we invite you to reapply in the
            future. Thank you for your time and consideration.
          </Typography>
          <Typography fontSize="25px">We hope you have a nice day.</Typography>
          <CButton
            fullWidth
            type="button"
            variant="contained"
            sx={{ borderRadius: "33px", paddingY: "19px", marginTop: 2 }}
            onClick={logout}
            name="confirmHELOCError"
          >
            Confirm
          </CButton>
        </Box>
      </Container>
    );
  }

  const isLoading =
    !typeOfCreditLine ||
    !user ||
    !property ||
    isLoadingOffer ||
    (typeOfCreditLine.loading && !isLoanAmountModalOpen);

  return (
    <Fragment>
      <CLoader loading={isLoading} />
      <Container
        maxWidth="md"
        //eslint-disable-next-line
        disableGutters={useMediaQuery(theme.breakpoints.only("xs"))}
      >
        <Box>
          <Typography
            mt={4}
            mb={5}
            variant="h1"
            lineHeight={1}
            color={theme.palette.text.primary}
            letterSpacing={-0.8}
            px={{ xs: 2, md: 0 }}
            fontSize={{ xs: "2rem", md: "3rem" }}
          >
            <span
              style={{ color: theme.palette.primary.main, marginRight: "10px" }}
            >
              Congrats {user?.firstName}!{" "}
            </span>{" "}
            You've been pre-qualified for a HELOC for up to{" "}
            <span style={{ color: theme.palette.primary.main }}>
              $
              {tnMaxOffer //temporary TN validation
                ? tnMaxOffer.toLocaleString("en-US")
                : Math.floor(
                    typeOfCreditLine?.loan?.initialOffer?.maxOffer ?? 0,
                  ).toLocaleString("en-US")}
            </span>
            !{!isFirstLien && " View your options below"}.
          </Typography>
        </Box>

        <WarningMessage loan={typeOfCreditLine?.loan} />

        <Box
          bgcolor={theme.palette.background.paper}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "36px",
            borderTopLeftRadius: "33px",
            borderTopRightRadius: "33px",
          }}
          py={"40px"}
          px={"70px"}
          mb="6px"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Typography fontSize={"22px"} maxWidth="220px" width="100%">
              Property
            </Typography>
            <Typography fontSize={"22px"} fontWeight={600}>
              {typeOfCreditLine?.loan?.property?.fullAddress}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Typography fontSize={"22px"} maxWidth="220px" width="100%">
              Loan Request
            </Typography>
            <Typography fontSize={"22px"} fontWeight={600}>
              {parsedLoanAmount}
            </Typography>
            {!bpoPending && (
              <CButton
                sx={{ p: "0px" }}
                onClick={() => setIsLoanAmountModalOpen(true)}
              >
                <Typography fontSize="20px" color="#6767AA" fontWeight={600}>
                  Edit
                </Typography>
              </CButton>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Typography fontSize={"22px"} maxWidth="220px" width="100%">
              Interest Rate
            </Typography>
            <Typography fontSize={"22px"} fontWeight={600}>
              {parsedInterestRate}{" "}
              <Typography
                variant="body1"
                display="inline"
                pl="12px"
                fontSize={"20px"}
                color="#979797"
              >
                ({parsedMonthlyPayment} est. monthly payment)
              </Typography>
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Typography fontSize={"22px"} maxWidth="220px" width="100%">
              Draw
            </Typography>
            {Number(drawPercentage) === PERCENTAGE ? (
              <Typography fontSize={"22px"} fontWeight={600}>
                {dollarFormatter.format(
                  typeOfCreditLine?.loan?.initialOffer?.initialDrawAmount ?? 0,
                )}{" "}
                {`(${drawPercentage}% draw)`}
              </Typography>
            ) : (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="12px"
              >
                <WarningIcon style={{ color: "#EAB308" }} />
                <Typography fontSize={"22px"} color="#EAB308" fontWeight={600}>
                  {dollarFormatter.format(
                    typeOfCreditLine?.loan?.initialOffer?.initialDrawAmount ??
                      0,
                  )}{" "}
                  {`(${drawPercentage}% draw)`}
                </Typography>
              </Box>
            )}
            <CButton
              sx={{ p: "0px" }}
              onClick={() => setIsDrawRequestModalOpen(true)}
            >
              <Typography fontSize="20px" color="#6767AA" fontWeight={600}>
                Edit
              </Typography>
            </CButton>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Typography fontSize={"22px"} maxWidth="220px" width="100%">
              Est. Points and Fees
            </Typography>
            <Typography fontSize={"22px"} fontWeight={600}>
              {parsedEstPointsAndFees}
            </Typography>
            <CButton
              sx={{ p: "0px", marginLeft: "12px" }}
              onClick={() => setIsFeesModalOpen(true)}
            >
              <Typography fontSize="20px" color="#6767AA" fontWeight={600}>
                Show details
              </Typography>
            </CButton>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Typography fontSize={"22px"} maxWidth="220px" width="100%">
              Est. Net Proceeds
            </Typography>
            <Typography fontSize={"22px"} fontWeight={600}>
              {parsedNetFundingWiredToBank}
            </Typography>
          </Box>
        </Box>

        <Box
          bgcolor={theme.palette.background.paper}
          sx={{
            borderBottomLeftRadius: "33px",
            borderBottomRightRadius: "33px",
          }}
          py={"65px"}
          px={"70px"}
        >
          <Typography fontSize={"22px"} fontWeight={600}>
            Here are some additional loan amount and rate options
          </Typography>

          <OfferOptionsByFico
            offertOptions={offertOptionsByFico}
            currentLoan={typeOfCreditLine.loan}
            loanId={loan?.id}
            handleSubmit={handleSubmit}
            isDisabled={
              !!(
                typeOfCreditLine?.loan?.borrowerSteps?.appraisalVerification &&
                typeOfCreditLine?.loan?.borrowerFlags?.enableAppraisal
              )
            }
          />
          <Typography fontSize="22px" mt="40px" sx={{ textWrap: "pretty" }}>
            Once you Accept Offer, we will confirm your debt-to-income ratio to
            ensure its under 50% by verifying your income electronically either
            by accessing your payroll system or you can connect to your online
            bank account.
          </Typography>

          <AcceptOfferButton
            functionHandler={() =>
              loanType.saveOffer(
                "HELOC",
                typeOfCreditLine.loan?.initialOffer?.initialDrawAmount ?? 0,
              )
            }
            isAccepting={loanType.isAccepting}
            isLoading={loanType.loading}
          />

          {isLoanAmountModalOpen ? (
            <EditInitialLoanAmount
              endpoint="/change-offer-amount/initial"
              handleRefreshLoan={typeOfCreditLine.handleRefresh}
              handleRefreshOffer={offer.handleRrefresh}
              handleSubmit={handleSubmit}
              open={isLoanAmountModalOpen}
              isFirstLien={isFirstLien}
              tnMaxOffer={
                tnMaxOffer ??
                typeOfCreditLine?.loan?.initialOffer?.maxOffer ??
                0
              } //temporary TN validation
              loanId={loan?.id}
              onSubmit={() => {
                typeOfCreditLine.handleRefresh();
              }}
              onClose={() => {
                setIsLoanAmountModalOpen(false);
              }}
              state={property?.address?.components?.state_abbreviation}
              setEditLoanAmountOpen={setIsLoanAmountModalOpen}
            />
          ) : null}

          {isDrawRequestModalOpen ? (
            <DrawRequestModal
              isOpen={isDrawRequestModalOpen}
              handleCloseModal={() => setIsDrawRequestModalOpen(false)}
              handleChangeDrawAmount={typeOfCreditLine.handleChangeDrawAmount}
              {...offer}
            />
          ) : null}

          {isFeesModalOpen ? (
            <FeesModal
              openModal={isFeesModalOpen}
              onClose={() => setIsFeesModalOpen(false)}
              loan={typeOfCreditLine?.loan}
              loanAmount={
                typeOfCreditLine?.loan?.initialOffer?.initialDrawAmount ?? 0
              }
              total={parsedEstPointsAndFees}
            />
          ) : null}
        </Box>
      </Container>
      {openModalConfirmTx && loanNotArchived && (
        <ConfirmTXNotHomestead
          open={openModalConfirmTx}
          onAccept={() => {
            setOpenModalConfirmTx(false);
          }}
        />
      )}
    </Fragment>
  );
};

export default TypeOfCreditLine;
