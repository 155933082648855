import { Box, Typography } from "@mui/material";
import CButton from "components/CButton";
import CDialog from "components/CDialog";
import { FC } from "react";
import Loan from "typedef/Loan";
import dollarFormatter from "utils/FormatterDollar";

interface Props {
  openModal: boolean;
  onClose: () => void;
  loan?: Loan;
  loanAmount: number;
  total: string;
}

const LEGAL_VESTING_FEES = 65;

const FeesModal: FC<Props> = ({
  openModal,
  onClose,
  loan,
  loanAmount,
  total,
}) => {
  const originationFee =
    loanAmount * (loan?.loanFees?.originationFeeTotal ?? 0);
  return (
    <CDialog open={openModal} onClose={onClose} maxWidth="md" fullWidth>
      <Box display="flex" flexDirection="column">
        <Typography fontSize="25px" fontWeight={600} textAlign={"left"}>
          Estimated Fees
        </Typography>
        <Typography
          fontSize="22px"
          fontWeight={600}
          textAlign={"left"}
          marginTop="25px"
        >
          Origination Fee (2.99%): {dollarFormatter.format(originationFee)}
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          marginTop="20px"
          marginBottom="20px"
        >
          <Typography fontSize="20px" fontWeight={600} textAlign="left">
            Fees:
          </Typography>
          <Typography fontSize="20px" textAlign={"left"}>
            Credit Report:{" "}
            {dollarFormatter.format(loan?.loanFees?.creditReport ?? 0)}
          </Typography>
          <Typography fontSize="20px" textAlign={"left"}>
            Compliance / Doc Prep / Enote:{" "}
            {dollarFormatter.format(
              loan?.loanFees?.complianceDocPrepeNote ?? 0,
            )}
          </Typography>

          <Typography fontSize="20px" textAlign={"left"}>
            Property AVM / Inspection:{" "}
            {dollarFormatter.format(loan?.loanFees?.AVMInspection ?? 0)}
          </Typography>

          <Typography fontSize="20px" textAlign={"left"}>
            ID Verification:{" "}
            {dollarFormatter.format(loan?.loanFees?.IDVerification ?? 0)}
          </Typography>

          <Typography fontSize="20px" textAlign={"left"}>
            eRecording:{" "}
            {dollarFormatter.format(
              loan?.loanFees?.valuesInNotary?.recordingFeeSimplifile ?? 0,
            )}
          </Typography>

          <Typography fontSize="20px" textAlign={"left"}>
            Legal and Vesting:{" "}
            {dollarFormatter.format(
              loan?.loanFees?.legalVestingFee ?? LEGAL_VESTING_FEES,
            )}
          </Typography>

          <Typography fontSize="20px" textAlign={"left"}>
            Taxes:{" "}
            {dollarFormatter.format(
              loan?.borrowerSteps?.titleVerification
                ?.recordingFeesAndOtherTaxes ?? 0,
            )}
          </Typography>
        </Box>
        <Typography fontSize="22px" fontWeight={600} textAlign="left">
          Total: {total}
        </Typography>
      </Box>
      <CButton
        type="button"
        variant="contained"
        fullWidth
        onClick={onClose}
        sx={{ borderRadius: "33px", paddingY: "19px", marginTop: 4 }}
      >
        Close Details
      </CButton>
    </CDialog>
  );
};

export default FeesModal;
