import API from "utils/API";

const runCreditLambda = async (loanId: string, userId: string) => {
  await API.post({
    url: "/invoke",
    data: {
      FunctionName: "validateCredit",
      Payload: {
        loanId: loanId,
        userId: userId,
      },
    },
  });
};

export default runCreditLambda;
