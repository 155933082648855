import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import CButton from "components/CButton";
import { FC, useState } from "react";
import ShowDetailsModal from "./showDetailsModal";
import { HELOCFormProps } from "typedef/HELOCFormProps";

const AMOUNT_OF_DECIMALS = 2;

const EstPointsAndFees: FC<HELOCFormProps> = ({
  loan,
  newValuesSlider,
  netFundingWiredToBankCalculation,
}) => {
  const theme = useTheme();
  const [showDetails, setShowDetails] = useState(false);

  const estPointsAndFees =
    Number(
      newValuesSlider?.initialDrawAmount
        ? newValuesSlider?.initialDrawAmount.toFixed(AMOUNT_OF_DECIMALS)
        : 0,
    ) - Number(netFundingWiredToBankCalculation?.toFixed(AMOUNT_OF_DECIMALS));
  const loanFees = loan?.loanFees;

  return (
    <>
      <Box
        display="grid"
        alignItems="center"
        sx={{ backgroundColor: theme.palette.background.paper, p: 2 }}
        gridTemplateColumns="1fr 2fr"
      >
        <Typography variant="h6">Est. Points And Fees</Typography>
        <Box display="flex" alignItems="center">
          {estPointsAndFees && newValuesSlider?.amount ? (
            <>
              <Typography
                variant="h5"
                color={theme.palette.text.primary}
                fontFamily={"Pro Display Bold"}
              >
                ${estPointsAndFees.toLocaleString("en-US")}
              </Typography>
              <CButton
                sx={{ minHeight: 0, minWidth: 0, padding: 0, ml: 2 }}
                onClick={() => setShowDetails(true)}
              >
                Show details
              </CButton>
            </>
          ) : (
            <Skeleton width="75%" height={"1.5rem"} />
          )}
        </Box>
      </Box>

      {showDetails && (
        <ShowDetailsModal
          openModal={showDetails}
          onClose={() => {
            setShowDetails(false);
          }}
          loanFees={loanFees}
          loan={loan}
          newValuesSlider={newValuesSlider}
        />
      )}
    </>
  );
};

export default EstPointsAndFees;
