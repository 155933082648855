import { Box, Typography, useTheme } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import Loan from "typedef/Loan";

const MAX_DTI_VALUE = 0.5;

const WarningMessage = ({ loan }: { loan?: Loan }) => {
  const theme = useTheme();

  if (
    (loan && loan?.initialOffer?.debtToIncomeRatio > MAX_DTI_VALUE) ||
    loan?.initialOffer?.useProceeds === "Debt Consolidation"
  ) {
    return (
      <Box
        sx={{
          borderRadius: "33px",
          mt: "40px",
          mb: "20px",
        }}
        bgcolor={theme.palette.background.paper}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems={"center"}
          gap="18px"
          sx={{ px: "70px", py: "32px" }}
        >
          <WarningIcon style={{ color: "#EAB308" }} />
          <Typography fontSize="20px">
            Please be advised that this offer is based on doing a debt
            consolidation.
          </Typography>
        </Box>
      </Box>
    );
  }

  if (
    loan?.borrowerSteps?.appraisalVerification &&
    loan?.borrowerFlags?.enableAppraisal
  ) {
    return (
      <Box
        sx={{
          borderRadius: "33px",
          mt: "40px",
          mb: "20px",
        }}
        bgcolor={theme.palette.background.paper}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems={"center"}
          gap="18px"
          sx={{ px: "70px", py: "32px" }}
        >
          <WarningIcon style={{ color: "#EAB308" }} />
          <Typography fontSize="20px">
            Special note regarding your property value. We were not able to
            confirm your property value with an AVM (Automated Valuation Model).
            We have ordered a BPO (Broker Price Opinion) and expect the results
            generally within 2-3 days. If the report comes back with a valuation
            that supports your application, then there will be no delays.
            However, if the BPO value is lower than expected, it might cause
            your offer to change and/or be withdrawn.
          </Typography>
        </Box>
      </Box>
    );
  }

  return null;
};

export default WarningMessage;
