import moment from "moment";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Box,
  IconButton,
  Pagination,
  Typography,
  useTheme,
} from "@mui/material";

export interface Doc {
  id: string;
  key: string;
  category: string;
  uploaderId: string;
  uploadDate: string;
  viewPermissions: string[];
  borrowerPermissions: boolean;
  url: string;
}

export interface CTableProps {
  documents: {
    page: number;
    pageItems: Doc[];
    pageLimit: number;
    loading: boolean;
  };
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  currentPage: number;
}

export default function CTable({
  documents,
  handleChange,
  currentPage,
}: CTableProps) {
  const theme = useTheme();

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ "&:first-child th": { border: 0 } }}>
              <TableCell align="left" sx={{ padding: 0 }}>
                <Typography
                  variant="subtitle1"
                  color={theme.palette.text.secondary}
                  whiteSpace={"nowrap"}
                >
                  Document name
                </Typography>
              </TableCell>
              <TableCell align="left" sx={{ px: 0, py: 1 }}>
                <Typography
                  variant="subtitle1"
                  color={theme.palette.text.secondary}
                  whiteSpace={"nowrap"}
                >
                  Last update
                </Typography>
              </TableCell>
              <TableCell align="right" sx={{ px: 0, py: 1 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents?.pageItems?.map((doc) => (
              <TableRow key={doc.key}>
                <TableCell align="left" sx={{ px: 0, py: 1 }} width={"50%"}>
                  <Typography
                    variant="subtitle1"
                    color={theme.palette.primary.main}
                    whiteSpace={"nowrap"}
                  >
                    {doc && doc?.key?.split("/")[3]}
                  </Typography>
                </TableCell>
                <TableCell align="left" sx={{ p: 0, py: 1 }} width={"40%"}>
                  {moment(doc?.uploadDate).format("YYYY-MM-DD - HH:mm:ss")}
                </TableCell>
                <TableCell align="right" sx={{ p: 0, py: 1 }} width={"5%"}>
                  <IconButton aria-label="delete" size="small">
                    <a target={"_blank"} rel="noreferrer" href={doc?.url}>
                      <FileDownloadIcon
                        htmlColor={theme.palette.primary.main}
                      />
                    </a>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box justifyContent={"flex-end"} display={"flex"} pt={2}>
        <Pagination
          count={documents.pageLimit}
          color="primary"
          onChange={handleChange}
          page={currentPage}
        />
      </Box>
    </>
  );
}
