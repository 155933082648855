import CTracker from "components/CTracker";
import { TrackerContextProvider } from "components/CTracker/useTrackerContext";
import useUserCustomerContext from "context/UserCustomer/useUserCustomerContext";
import ReapplicationModal from "screens/BorrowerTracker/ReapplicationModal";

const CoborrowerTracker: React.FC = () => {
  const { loan } = useUserCustomerContext();

  const isLoanArchived = loan?.borrowerFlags?.archiveType;

  if (isLoanArchived) {
    return <ReapplicationModal loan={loan} />;
  }

  return (
    <TrackerContextProvider>
      <CTracker isBorrower={false} />
    </TrackerContextProvider>
  );
};

export default CoborrowerTracker;
