import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import HELOCForm from "components/HELOCForm";
import useHelocOffer from "./useHelocOffer";
import FinalOfferMessages, { Messages } from "./FinalOfferMessages";
import ConfirmButton from "./ConfirmButton";
import TitleMessages from "./TitleMessages";
import CLoader from "components/CLoader";

const HELOCOffer = () => {
  const offer = useHelocOffer();
  const theme = useTheme();
  const initialOffer = offer.loan?.initialOffer;
  const finalOffer = offer.loan?.finalOffer;
  const apraisalVerification = offer.loan?.borrowerSteps?.appraisalVerification;
  const hardCreditScore =
    offer.loan?.borrowerSteps?.hardpullVerification?.hardCreditScore;
  const reasons: (keyof Messages)[] = [];
  const stateCap = offer.initialValuesSliderHELOC?.stateCap;
  const thereIsOffer = !offer.errorOffer;

  if (
    initialOffer?.interestRate !== finalOffer?.interestRate &&
    initialOffer?.creditScore !== hardCreditScore
  ) {
    reasons.push("CLTVAndFICO");
  }
  if (
    initialOffer?.cltv !== finalOffer?.cltv &&
    initialOffer?.maxOffer !== finalOffer?.maxOffer
  ) {
    reasons.push("CLTV");
  }
  if (
    apraisalVerification?.appraisalType === "BPO" &&
    apraisalVerification?.status === "analyzing"
  ) {
    reasons.push("BPOIsOutstanding");
  }
  if (offer.drawLessThan100) {
    reasons.push("drawLessThan100");
  }
  if (
    stateCap &&
    ((initialOffer?.interestRate !== finalOffer?.interestRate &&
      initialOffer?.creditScore !== hardCreditScore) ||
      (initialOffer?.cltv !== finalOffer?.cltv &&
        initialOffer?.maxOffer !== finalOffer?.maxOffer))
  ) {
    reasons.push("StateRegulations");
  }
  const isLoading = offer.loading || !offer.newValuesSlider;

  return (
    <Container>
      <CLoader loading={isLoading} />
      <Container
        maxWidth="md"
        disableGutters={useMediaQuery(theme.breakpoints.only("xs"))}
      >
        <Box>
          <TitleMessages thereIsOffer={thereIsOffer} />
        </Box>

        {thereIsOffer && (
          <>
            {reasons.length > 0 && (
              <Box
                mb={2.5}
                px={{ xs: 2, md: 6 }}
                py={{ xs: 1, md: 4 }}
                bgcolor={theme.palette.background.paper}
                borderRadius={8}
              >
                <FinalOfferMessages
                  reasons={reasons}
                  errorMessage={offer.errorMessage}
                />
              </Box>
            )}
            <HELOCForm {...offer} />
          </>
        )}
        <ConfirmButton
          thereIsOffer={thereIsOffer}
          archiveLoan={offer.archiveLoan}
          offer={offer}
        />
      </Container>
    </Container>
  );
};

export default HELOCOffer;

export type HELOCOfferType = ReturnType<typeof useHelocOffer>;
