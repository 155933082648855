import Address from "typedef/Address";
import parseMoney from "utils/parseMoney";
import { useParams } from "react-router-dom";
import { RegisterFormInputs } from "./useRegisterForm";
import { useInvite } from "context/Invite/useInviteContext";

const useParseToSubmit = () => {
  const invite = useInvite();
  const params = useParams();
  const sourceId = params["sourceId"];

  return (data: RegisterFormInputs) => {
    const annualIncome = parseMoney(data.annualIncome);
    const homeValue = parseMoney(data.homeValue);
    const requestedNewLoan = parseMoney(data.requestedNewLoan);
    const currentLoanBalance = parseMoney(data.currentLoanBalance);
    const phoneNumber = data.phoneNumber.replace(/ /g, "");
    const creditScore = parseInt(data.creditScore?.split(" ")[0]);
    const { street_line, ...restOfAddress } = data.propertyAddress;
    const address = {
      street: street_line,
      ...restOfAddress,
    };
    let borrowerAddress: (Address & { street?: string }) | undefined;
    if (data.borrowerAddress) {
      const { ...propsBorrowerAddress } = data.borrowerAddress;

      borrowerAddress = {
        street: propsBorrowerAddress?.street_line,
        ...propsBorrowerAddress,
      };
    }

    return {
      address,
      agreeNotifications: true,
      annualIncome: annualIncome,
      borrowerAddress,
      creditScore,
      currentLoanBalance: currentLoanBalance,
      customAddress:
        data.propertyAddress?.street_line === "My address is not listed"
          ? {
              city: data?.manual_city,
              secondary: data?.manual_apt,
              state: data?.manual_state?.toLocaleUpperCase(),
              street_name: data?.manual_street_name,
              street_number: data?.manual_street_number,
              zipcode: data?.manual_zipcode,
              street_suffix: data?.manual_street_suffix,
            }
          : undefined,
      customBorrowerAddress:
        data.borrowerAddress?.street_line === "My address is not listed"
          ? {
              city: data?.manual_borrower_city,
              secondary: data?.manual_borrower_apt,
              state: data?.manual_borrower_state?.toLocaleUpperCase(),
              street_name: data?.manual_borrower_street_name,
              street_number: data?.manual_borrower_street_number,
              zipcode: data?.manual_borrower_zipcode,
              street_suffix: data?.manual_borrower_street_suffix,
            }
          : undefined,
      email: data.email?.trim(),
      employerNameBorrower: data.employerNameBorrower ?? undefined,
      employmentType: data.employmentType,
      maritalStatus: data.maritalStatus,
      firstName: data.firstName?.trim(),
      hasAcceptTerms: true,
      homeValue: homeValue,
      inviteCode: invite?.code,
      lastName: data.lastName?.trim(),
      manualPropertyAddress:
        data.propertyAddress?.street_line === "My address is not listed",
      manualBorrowerAddress:
        data.borrowerAddress?.street_line === "My address is not listed",
      occupancy: data.occupancy,
      password: data.password,
      phoneNumber,
      requestedNewLoan: requestedNewLoan,
      sourceId: sourceId,
      startDateBorrower: data.startDateBorrower ?? undefined,
      useProceeds: data.useProceeds,
    };
  };
};

export default useParseToSubmit;
