import API from "utils/API";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Buffer } from "buffer";
import useLoan from "components/CTracker/useLoan";
import { NeededDoc } from ".";
import { isFilePdf } from "utils/validateFile";

export type DocumentUploadFormInputs = {
  document: string;
  fileType: string;
  fileExt: string;
  selectedDocument: NeededDoc;
  taxYear?: string;
};

const useDocumentUploadForm = (handleClose: () => void) => {
  const loan = useLoan();
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState<string>();

  const form = useForm<DocumentUploadFormInputs>();

  form.register("document", {
    required: {
      value: true,
      message: "Document is required",
    },
  });

  async function convertToBase64(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedFile = event.target.files as FileList | null | undefined;
    if (JSON.stringify(selectedFile)?.length > 0) {
      form.clearErrors("document");
      const fileToLoad = selectedFile?.[0];

      const isPdf = await isFilePdf(fileToLoad as File);

      const megabyteInBytes = 1048576;
      const maxSizeInMb = 100;
      const sizeInMB = fileToLoad && fileToLoad.size / megabyteInBytes;
      const fileType = selectedFile?.[0].type as string;
      const fileExt = selectedFile?.[0].name.split(".").pop() as string;
      if (!isPdf) {
        event.target.value = "";
        form.setError("document", { message: "File type not supported" });
      }
      if (sizeInMB && sizeInMB > maxSizeInMb) {
        event.target.value = "";
        form.setError("document", {
          message: `You can't upload files larger than ${maxSizeInMb} MB`,
        });
      } else {
        const fileReader = new FileReader();
        fileReader.onload = function (fileLoadedEvent) {
          form.setValue("document", fileLoadedEvent?.target?.result as string);
          form.setValue("fileType", fileType);
          form.setValue("fileExt", fileExt);
        };
        fileReader.readAsDataURL(fileToLoad as Blob);
      }
    }
  }
  const onReset = () => {
    form.reset();
    setErrorSubmit("");
    handleClose();
  };

  const uploadDocument = form.handleSubmit(async (data) => {
    setLoading(true);
    setErrorSubmit(undefined);

    /** This logic extracts Borrower and Co-borrower from the document name */

    const match = data.selectedDocument.docName.match(/(Borrower|Co-borrower)/);
    const bValue = match?.length ? match[0] : "";
    const remainingString = data.selectedDocument.docName.replace(bValue, "");
    const documentName = match
      ? remainingString.trim()
      : data.selectedDocument.docName;
    const document = {
      loanId: loan?.id,
      fileType: data.fileType,
      userId: data.selectedDocument.isBorrower
        ? loan?.borrowerId
        : loan?.coborrowerId,
      extension: data.fileExt,
      docOption: documentName,
      step: data.selectedDocument.step,
      selectedTaxYear: data?.taxYear,
      companyName: data?.selectedDocument?.companyName,
      paidAndClosedLetter: data?.selectedDocument?.paidAndClosedLetter,
    };

    const result = (await API.post({
      url: "/upload-docs-borrower/get-presignedurl",
      data: document,
    })) as {
      error?: string;
      data?: { presignedUrl: string; docKey: string; documentName: string };
    };

    if ("error" in result) {
      setErrorSubmit(result.error);
    } else {
      const base64Data = data.document.split(",")[1];
      const buffer = Buffer.from(base64Data, "base64");
      const presignedUrl = result?.data?.presignedUrl as string;
      const docKey = result?.data?.docKey;

      const response = await fetch(presignedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": data.fileType,
        },
        body: buffer,
      });

      const failedStatus = 300;
      if (response.status >= failedStatus) {
        setErrorSubmit(`Error uploading file`);
      } else {
        const confirmResult = (await API.post({
          url: "/upload-docs-borrower/confirm-document-save",
          data: {
            loanId: loan?.id,
            userId: data.selectedDocument.isBorrower
              ? loan?.borrowerId
              : loan?.coborrowerId,
            docKey: docKey,
            docOption: { docName: documentName },
            step: data.selectedDocument.step,
            uploaderId: data.selectedDocument.isBorrower
              ? loan?.borrowerId
              : loan?.coborrowerId,
            selectedTaxYear: data?.taxYear,
            payOffId: data?.selectedDocument?.payOffId,
            paidAndClosedLetter: data?.selectedDocument?.paidAndClosedLetter,
          },
        })) as { error?: string; data?: { message: string } };
        if ("error" in confirmResult) {
          setErrorSubmit(confirmResult.error);
        } else {
          onReset();
        }
      }
    }
    setLoading(false);
  });

  return {
    loading,
    ...form,
    onReset,
    openDialog,
    errorSubmit,
    setOpenDialog,
    uploadDocument,
    convertToBase64,
  };
};

export default useDocumentUploadForm;
