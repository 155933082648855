import { useState } from "react";
import Loan from "typedef/Loan";
import API from "utils/API";
const useClosingRon = ({ loan }: { loan: Loan }) => {
  const [isCloseNowModalOpen, setIsCloseNowModalOpen] = useState(false);
  const [isLoadingFinalDisc, setIsLoadingFinalDisc] = useState(false);
  const handleConfirmClosingNowModal = () => {
    setIsCloseNowModalOpen((prev) => !prev);
  };
  const handlSendFinalDisclosures = async () => {
    setIsLoadingFinalDisc(true);
    setIsCloseNowModalOpen(true);
    await API.post({
      url: "/invoke",
      data: {
        InvocationType: "RequestResponse",
        FunctionName: "sendFinalDisclosures",
        Service: "nftydoor-app",
        Payload: {
          loanId: loan?.id,
          generatedByBorrower: true,
        },
      },
    });
    setIsLoadingFinalDisc(false);
  };
  return {
    handlSendFinalDisclosures,
    handleConfirmClosingNowModal,
    isCloseNowModalOpen,
    isLoadingFinalDisc,
  };
};
export default useClosingRon;
