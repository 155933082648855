import { Box, Typography, useTheme } from "@mui/material";

interface Prop {
  phone?: string;
}

export default function LoanOfficerInformation({ phone }: Prop) {
  const theme = useTheme();
  return (
    <Box
      display={"flex"}
      borderRadius={10}
      bgcolor={theme.palette.background.paper}
      mb={3}
      py={5}
      px={7}
      position="relative"
      justifyContent={"space-between"}
      alignItems={{ xs: "center", sm: "flex-start" }}
      flexDirection={{ xs: "column", sm: "row" }}
    >
      <Box py={{ xs: 0, sm: 0 }} mt={{ xs: 0, sm: 0 }} flex={1}>
        <Typography
          variant="h6"
          color={theme.palette.text.primary}
          textAlign={"left"}
        >
          Please fill out the details in this form and then we can get started.
          <br />{" "}
          {phone ? (
            <>
              Call me at{" "}
              <span style={{ color: theme.palette.primary.main }}>{phone}</span>
            </>
          ) : (
            <>Contact me</>
          )}{" "}
          if you have any questions.
        </Typography>
      </Box>
    </Box>
  );
}
