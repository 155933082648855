import CButton from "components/CButton";
import CDialog from "components/CDialog";
import {
  Box,
  Checkbox,
  ClickAwayListener,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import runHardCreditLambda from "utils/runHardCreditLambda";
import API from "utils/API";
import useTracker from "components/CTracker/useTrackerContext";

interface ConsentForHardpullProps {
  isBorrower: boolean;
}

const ConsentForHardpull: React.FC<ConsentForHardpullProps> = ({
  isBorrower,
}) => {
  const { loan } = useTracker();
  const [consent, setConsent] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { privateLabel } = usePrivateLabel();
  const theme = useTheme();
  const waitingTime = 11000;
  const [firstConsent, setFirstConsent] = useState<boolean>(false);
  const [secondConsent, setSecondConsent] = useState<boolean>(false);

  const state = loan?.property?.address.components?.state_abbreviation;
  let messageByState = "";
  switch (state) {
    case "VT":
      messageByState =
        "I/We understand that the purpose for this order and verification is for to evaluate my/our eligibility and creditworthiness for a potential mortgage loan, before an application for such a loan is submitted. Vt. Stat. Ann. tit. 9, § 2480e Vt. Admin. Code 3-2-110:CP 112.03";
      break;
    case "RI":
      messageByState =
        "This disclosure is provided to you pursuant to R.I. Gen. Laws § 6-13.1-21(a). You acknowledge and agree that verification of any information contained in the loan application may be made at any time directly or through a credit reporting agency by means of a credit report.";
      break;
    default:
      messageByState = "";
      break;
  }

  const hasConsented = isBorrower
    ? !loan?.borrowerFlags?.boConsentedHardpull
    : !loan?.coborrowerFlags?.boConsentedHardpull;

  useEffect(() => {
    if (hasConsented) {
      const timer = setTimeout(() => {
        setModalOpen(true);
      }, waitingTime);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [hasConsented]);

  const lenderName = privateLabel?.isNFTYDoor
    ? "Homebridge Financial Services, Inc."
    : privateLabel?.lenderName;

  const loanId = loan?.id;
  const userId = isBorrower ? loan?.borrowerId : loan?.coborrowerId;
  const user = isBorrower ? "borrower" : "coborrower";
  const handleGiveConsentAndStartHardpull = async () => {
    setLoading(true);
    if (!loanId || !userId) return;

    const response = await API.post({
      url: `/save-to-loan/flags?entity=${user}&loanId=${loanId}`,
      data: {
        boConsentedHardpull: true,
      },
    });
    if (response.status !== 200) {
      throw new Error("Failed to save consent flag");
    } else {
      await runHardCreditLambda(loanId, userId);
      setLoading(false);
      setModalOpen(false);
    }
  };

  const handleFirstConsent = () => {
    setFirstConsent((prev: boolean) => !prev);
  };

  const handleSecondConsent = () => {
    setSecondConsent((prev: boolean) => !prev);
  };

  useEffect(() => {
    if (firstConsent && secondConsent) {
      setConsent(true);
    } else if (firstConsent && !messageByState) {
      setConsent(true);
    } else {
      setConsent(false);
    }
  }, [firstConsent, secondConsent, messageByState]);

  return (
    <div>
      {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
      <ClickAwayListener onClickAway={() => {}}>
        <CDialog
          fullWidth
          maxWidth="sm"
          icon="warning"
          scroll={"paper"}
          sx={{ padding: "2rem" }}
          title="We need to complete a Hard Pull of your credit"
          open={modalOpen}
          disableEscapeKeyDown
        >
          <Box
            display={"flex"}
            maxWidth="xs"
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent={{ xs: "center", md: "left" }}
            alignItems="flex-start"
          >
            <Box display="inline" fontFamily="Pro Display Semibold">
              <Checkbox onClick={() => handleFirstConsent()} />
            </Box>
            <Box ml={1}>
              <Typography
                mt={0.8}
                variant="subtitle1"
                color={theme.palette.text.primary}
                textAlign={"justify"}
              >
                By clicking <b>"Accept & Continue"</b>, I am providing ‘written
                instructions’ to <b>{lenderName}</b> under the Fair Credit
                Reporting Act authorizing <b>{lenderName}</b> to obtain
                information from my personal credit profile or other information
                related to my employment and income information.
              </Typography>
            </Box>
          </Box>

          {messageByState && (
            <Box
              display={"flex"}
              maxWidth="xs"
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent={{ xs: "center", md: "left" }}
              alignItems="flex-start"
            >
              <Box display="inline" fontFamily="Pro Display Semibold">
                <Checkbox onClick={() => handleSecondConsent()} />
              </Box>
              <Box ml={1}>
                <Typography
                  mt={0.8}
                  variant="subtitle1"
                  color={theme.palette.text.primary}
                  textAlign={"justify"}
                >
                  {messageByState}
                </Typography>
              </Box>
            </Box>
          )}

          <Box mt={4}>
            <CButton
              fullWidth
              type="submit"
              variant="contained"
              loading={loading}
              onClick={() => handleGiveConsentAndStartHardpull()}
              disabled={consent === false}
              name="consentForHardpullDialog-accept"
              aria-label="consentForHardpullDialog-accept"
            >
              Accept & Continue
            </CButton>
          </Box>
        </CDialog>
      </ClickAwayListener>
    </div>
  );
};

export default ConsentForHardpull;
