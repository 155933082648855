import API from "utils/API";
import moment from "moment";
import { useEffect, useState } from "react";
import Address from "typedef/Address";
import { useForm } from "react-hook-form";
import { RegisterResponseType } from "typedef/RegisterForm";
import { useSearchParams } from "react-router-dom";
import parseMoney from "utils/parseMoney";
import { InviteContextType } from "context/Invite/useInviteContext";

export type RegisterFormCoborrowerInputs = {
  email: string;
  agree: boolean;
  certify: boolean;
  consent: boolean;
  password: string;
  lastName: string;
  firstName: string;
  phoneNumber: string;
  employmentType: string;
  maritalStatus: string;
  primaryBorrower: string;
  propertyAddress: string;
  address?: Address | null;
  socialSecurityNumber: string;
  dateOfBirth: string | undefined;
  annualIncome: string;
  employerNameCoborrower: string;
  startDateCoborrower: string;
  manualAddress?: Address;
};

export type RegisterFormCoborrowerDialogs =
  | "terms"
  | "privacy"
  | "verify"
  | "error"
  | "communications"
  | "EMAIL_IN_USE_ON_PL";

const useRegisterFormCoborrower = (invite?: InviteContextType) => {
  const [searchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [openedDialog, setOpenedDialog] =
    useState<RegisterFormCoborrowerDialogs>();
  const [emailAlreadyUseOnPL, setEmailAlreadyUseOnPL] = useState<string>("");
  const [addressData, setAddressData] = useState<Address>();
  const form = useForm<RegisterFormCoborrowerInputs>();

  useEffect(() => {
    if (invite) {
      const address = invite?.customAddress ?? invite.customCoborrowerAddress;
      form.reset({
        employmentType: invite?.employmentType,
        maritalStatus: invite?.maritalStatus,
        dateOfBirth: invite?.dateOfBirth ?? invite?.body?.dateOfBirthCoborrower,
        socialSecurityNumber:
          invite?.socialSecurityNumber ?? invite?.body?.ssnCoborrower,
        annualIncome: invite?.annualIncome,
        phoneNumber: invite?.phoneNumber,
        lastName: invite?.lastName,
        firstName: invite?.firstName,
        address: invite?.address as Address,
        startDateCoborrower: invite?.startDateCoborrower,
        employerNameCoborrower: invite?.employerNameCoborrower,
        manualAddress: {
          city: address?.city,
          secondary: address?.secondary,
          state: address?.state,
          street_line: address
            ? `${address?.street_number} ${address?.street_name} ${address?.street_suffix}`
            : undefined,
          zipcode: address?.zipcode,
        },
      });
      form.setValue(
        "socialSecurityNumber",
        invite?.socialSecurityNumber ?? invite?.body?.ssnCoborrower ?? "",
      );
      setAddressData(invite?.address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invite]);

  const onSubmit = form.handleSubmit(async (data) => {
    if (!addressData || !addressData.street_line) {
      return form.setError("propertyAddress", {
        message: "This field is required",
      });
    }
    const { street_line, ...address } = addressData as Address;
    const street = street_line;

    const response = await API.post<RegisterResponseType>({
      url: `/register-user/coborrower`,
      data: {
        address: {
          street,
          ...address,
        },
        hasAcceptTerms: true,
        agreeNotifications: true,
        email: invite?.email?.trim(),
        password: data.password,
        lastName: data.lastName?.trim(),
        firstName: data.firstName?.trim(),
        employmentType: data.employmentType,
        maritalStatus: data.maritalStatus,
        phoneNumber: data.phoneNumber.replace(/ /g, ""),
        socialSecurityNumber: data.socialSecurityNumber.replace(/-/g, ""),
        dateOfBirth: moment(data.dateOfBirth).format("yyyy/MM/DD"),
        annualIncome: parseMoney(data.annualIncome),
        code: searchParams.get("code"),
        employerNameCoborrower: data.employerNameCoborrower,
        startDateCoborrower: data.startDateCoborrower,
        manualAddress: {
          ...data.manualAddress,
          street: data.manualAddress?.street_line,
          state: data.manualAddress?.state?.toLocaleUpperCase(),
        },
      },
    });
    if ("error" in response) {
      setErrorMessage(response.error);
      setOpenedDialog("error");
    } else if (response?.data?.userAccountNoNeedVerification?.message) {
      setEmailAlreadyUseOnPL(
        response.data.userAccountNoNeedVerification.message,
      );
      setOpenedDialog("EMAIL_IN_USE_ON_PL");
    } else {
      setOpenedDialog("verify");
    }
  });

  return {
    onSubmit,
    openedDialog,
    errorMessage,
    setOpenedDialog,
    emailAlreadyUseOnPL,
    setErrorMessage,
    addressData,
    setAddressData,
    ...form,
  };
};

export default useRegisterFormCoborrower;
