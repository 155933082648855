import { Box, useTheme } from "@mui/material";
import { FC, Fragment, useState } from "react";
import TermsOfTheLoan from "screens/TypeOfCreditLine/TermsOfTheLoanHELOC";
import LoanRequest from "./LoanRequest";
import InterestRate from "./InterestRate";
import Draw from "./Draw";
import Property from "./Property";
import { HELOCFormProps } from "typedef/HELOCFormProps";
import EstPointsAndFees from "./EstPointsAndFees";
import EstNetProceeds from "./EstNetProceeds";

const HELOCForm: FC<HELOCFormProps> = (props) => {
  const offer = props;
  const loan = props.loan;
  const theme = useTheme();

  const [termsOfTheLoan, setTermsOfTheLoan] = useState(false);

  return (
    <Fragment>
      <Box
        py={2}
        bgcolor={theme.palette.background.paper}
        sx={{ borderTopLeftRadius: 34, borderTopRightRadius: 34 }}
      >
        <Box px={{ xs: 2, md: 6 }} py={{ xs: 1, md: 3 }} display="grid" gap={1}>
          <Property {...offer} />
          <LoanRequest {...offer} />
          <InterestRate {...offer} />
          <Draw {...offer} />
          <EstPointsAndFees {...offer} />
          <EstNetProceeds {...offer} />
        </Box>
      </Box>
      <TermsOfTheLoan
        loan={loan}
        open={termsOfTheLoan}
        handleClose={() => setTermsOfTheLoan(false)}
      />
    </Fragment>
  );
};

export default HELOCForm;
