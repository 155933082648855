import API from "utils/API";
import Loan from "typedef/Loan";
import { useForm } from "react-hook-form";
import parseMoney from "utils/parseMoney";
import { useEffect, useState } from "react";
import DrawRequests from "typedef/DrawRequests";

const useDrawHELOC = (loan?: Loan) => {
  const form = useForm();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [drawInfo, setDrawInfo] = useState<DrawRequests>();
  const [refreshDrawinfo, setRefreshDrawinfo] = useState(false);
  const isHELOC = loan?.loanType === "HELOC";
  const isTexas = loan?.property.address.components.state_abbreviation === "TX";

  useEffect(() => {
    if (isHELOC)
      API.get<DrawRequests>(`/get/get-my-draw-info`).then((res) => {
        if ("error" in res) {
          console.error(res);
        } else {
          setDrawInfo(res.data);
          setRefreshDrawinfo(false);
        }
      });
  }, [refreshDrawinfo, isHELOC]);

  const handleCloseModal = () => {
    setError("");
    form.reset();
    form.resetField("subsequentAmount");
    setOpenModal(false);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const onSubmit = form.handleSubmit(async (data) => {
    setLoading(true);
    const response = await API.post({
      url: `/create-subsequent-draw?loanId=${loan?.id}`,
      data: {
        subsequentDrawAmount: parseMoney(data.subsequentAmount),
      },
    });
    if ("error" in response) {
      setError(response.error);
    } else {
      setOpenModal(false);
      setOpenModalConfirm(true);
      setRefreshDrawinfo(!refreshDrawinfo);
      setError("");
      form.reset();
      form.resetField("subsequentAmount");
    }
    setLoading(false);
  });

  return {
    form,
    error,
    loading,
    onSubmit,
    drawInfo,
    openModal,
    handleOpenModal,
    openModalConfirm,
    handleCloseModal,
    handleCloseModalConfirm,
    isTexas,
  };
};

export default useDrawHELOC;
