import API from "utils/API";
import { useState, useEffect } from "react";
import Loan from "typedef/Loan";
export type TermsOfTheLoanHELOCProps = {
  primeRate: number;
};
const useTermsOfTheLoanHELOC = (loan?: Loan) => {
  const [helocPricingEngine, setHelocPricingEngine] = useState<
    TermsOfTheLoanHELOCProps | undefined
  >();
  useEffect(() => {
    (async () => {
      if (loan) {
        const encodePricingEngine = encodeURIComponent(loan.pricingEngineId);
        const response = await API.get<TermsOfTheLoanHELOCProps>(
          `/get/heloc-pricing-engine?date=${loan.pricingEngineCreatedAt}&id=${encodePricingEngine}`,
        );
        if ("error" in response) {
          console.error(response.error);
        } else {
          setHelocPricingEngine(response.data);
        }
      }
    })();
  }, [loan?.pricingEngineCreatedAt, loan?.pricingEngineId, loan]);
  return {
    helocPricingEngine,
  };
};
export default useTermsOfTheLoanHELOC;
