import Typography from "@mui/material/Typography";
import CTrackerTimelineItem from "components/CTimelineItem";
import useTracker from "components/CTracker/useTrackerContext";
import { useMemo } from "react";
import { useTheme } from "@mui/material";
import { TrackerStatusColor } from "typedef/TrackerStatusColor";
import { mergeMissingDocsByStep } from "utils/missingDocuments";
import UploadEachDocument from "components/CTracker/StepsDialogs/UploadEachDocument";

const TitleVerification: React.FC<{
  trackerStatusColor: TrackerStatusColor;
}> = ({ trackerStatusColor }) => {
  const theme = useTheme();
  const { loan } = useTracker();

  const borrowerSteps = loan?.borrowerSteps;
  const borrowerDone =
    (borrowerSteps?.incomeVerification?.status === "success" &&
      borrowerSteps?.disclosuresVerification?.status === "success") ||
    borrowerSteps?.titleVerification?.status === "success";

  const coborrowerDone = loan?.coborrowerSteps
    ? (loan.coborrowerSteps?.incomeVerification.status === "success" &&
        loan.coborrowerSteps?.disclosuresVerification.status === "success") ||
      borrowerSteps?.titleVerification?.status === "success"
    : true;

  const prevStep =
    (borrowerSteps?.creditVerification.status === "success" ||
      (borrowerSteps?.creditVerification.status === "analyzing" &&
        (borrowerSteps?.creditVerification.needDocReview !== undefined ||
          borrowerSteps?.creditVerification.deletedDocRequest))) &&
    borrowerSteps?.homeValuationVerification.status === "success" &&
    borrowerDone &&
    coborrowerDone;
  const status = prevStep
    ? borrowerSteps?.titleVerification?.status ?? "pending"
    : "pending";
  const message = borrowerSteps?.titleVerification?.message ?? "";

  const data = borrowerSteps?.titleVerification?.data;

  const needDocsUpload = borrowerSteps?.titleVerification?.needDocReview;

  const stepName = "titleVerification";

  const docsToUpload = useMemo(() => {
    if (!loan || !loan?.borrowerMissingDocs) return [];

    return mergeMissingDocsByStep(loan, stepName);
  }, [loan]);

  return (
    <CTrackerTimelineItem
      status={status}
      title="Title"
      tooltip="Title review is our final step prior to finalizing your loan documents."
    >
      {status === "failed" && data && (
        <Typography variant="h6" color={theme.palette.text.primary}>
          {message}
        </Typography>
      )}
      <Typography variant="h6" color={trackerStatusColor[status]}>
        {status === "analyzing" &&
          (needDocsUpload === true || needDocsUpload === undefined) &&
          (message || "Analyzing")}
        {status === "analyzing" && needDocsUpload === false && (
          <UploadEachDocument docsToUpload={docsToUpload} loan={loan} />
        )}
        {status === "failed" && !data && message}
        {status === "failed" && data && data}
        {status === "success" && message}
      </Typography>
    </CTrackerTimelineItem>
  );
};
export default TitleVerification;
