import { useEffect, useState } from "react";
import Loan from "typedef/Loan";
import API from "utils/API";

type Payments = {
  numberOfMonthlyPaymentsDone: number;
};

const useMonthlyPaymentsDone = (loan?: Loan) => {
  const [payments, setPayments] = useState<number>(0);

  useEffect(() => {
    if (loan?.id) {
      const loanId = loan.id;
      API.get<Payments>(`/next-payment?loanId=${loanId}`).then((res) => {
        if ("error" in res) {
          console.error(res);
        } else {
          setPayments(res.data.numberOfMonthlyPaymentsDone);
        }
      });
    }
  }, [loan]);

  return payments;
};

export default useMonthlyPaymentsDone;
