import { Fragment } from "react";
import CButton from "components/CButton";
import useLoginForm from "./useLoginForm";
import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  FormHelperText,
  useTheme,
} from "@mui/material";
import CTextField from "components/CTextField";
import { Link, useNavigate } from "react-router-dom";
import CPasswordField from "components/CPasswordField";
import useUser from "context/UserCustomer/useUserCustomerContext";
import { passwordMsgHelper, regexPassword } from "CONST";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";

export default function Login() {
  const theme = useTheme();
  const form = useLoginForm();
  const navigate = useNavigate();
  const { isGettingLoggedIn } = useUser();
  const { privateLabel } = usePrivateLabel();

  return (
    <Fragment>
      <Container
        maxWidth="md"
        disableGutters={useMediaQuery(theme.breakpoints.only("xs"))}
      >
        <Box>
          <Typography
            mt={4}
            mb={5}
            variant="h1"
            lineHeight={1}
            color={theme.palette.text.primary}
            letterSpacing={-0.8}
            pl={{ xs: 2, md: 0 }}
            fontSize={{ xs: "3.5rem", md: "6rem" }}
          >
            <span style={{ color: theme.palette.primary.main }}>Hello!</span>
            <br />
            Login here.
          </Typography>
        </Box>
        <Box py={2} bgcolor={theme.palette.background.paper} borderRadius={8}>
          <form onSubmit={form.onSubmit}>
            <Box px={{ xs: 2, md: 6 }} py={{ xs: 3, md: 6 }}>
              <CTextField
                label="Email address"
                error={form.formState.errors.email?.message}
                placeholder="Enter your email here"
                fullWidth
                sx={{ mb: 3 }}
                {...form.register("email", {
                  required: {
                    value: true,
                    message: "Email is required.",
                  },
                  pattern: {
                    value:
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i,
                    message: "The email format is incorrect.",
                  },
                })}
              />
              <CPasswordField
                label="Password"
                error={form.formState.errors.password?.message}
                placeholder="Type in here"
                fullWidth
                {...form.register("password", {
                  required: {
                    value: true,
                    message: "Password is required.",
                  },
                  minLength: {
                    value: 9,
                    message: "The password must be at least 9 characters long.",
                  },
                  maxLength: {
                    value: 100,
                    message: "The password must be less than 100 characters.",
                  },
                  pattern: {
                    value: regexPassword,
                    message: passwordMsgHelper,
                  },
                })}
              />
              <Link to={"/forgot-password"} style={{ textDecoration: "none" }}>
                <Typography
                  mt={1}
                  color={theme.palette.primary.main}
                  variant="subtitle1"
                >
                  Forgot your password?
                </Typography>
              </Link>

              <FormHelperText error={Boolean(form.loginError)}>
                <Typography mb={3} textAlign={"center"} variant="subtitle1">
                  {form.loginError === "User is not confirmed." ? (
                    <Link
                      to={`/resend-validate-code?email=${form.watch("email")}`}
                      style={{
                        textDecoration: "none",
                        color: theme.palette.error.main,
                      }}
                    >
                      User is not confirmed. Click here to confirm
                    </Link>
                  ) : (
                    form.loginError
                  )}
                </Typography>
              </FormHelperText>

              <CButton
                fullWidth
                type="submit"
                variant="contained"
                loading={isGettingLoggedIn}
              >
                Log in
              </CButton>
              {privateLabel?.hasOrganicFlow && (
                <Box mt={2}>
                  <CButton
                    fullWidth
                    type="submit"
                    variant="text"
                    onClick={() => navigate("/signup", { replace: true })}
                  >
                    Create your Account Here
                  </CButton>
                </Box>
              )}
            </Box>
          </form>
        </Box>
      </Container>
    </Fragment>
  );
}
