import {
  Box,
  Checkbox,
  FormGroup,
  Typography,
  FormControlLabel,
  useTheme,
} from "@mui/material";
import { FC, Fragment, useCallback } from "react";
import { UseFormReturn } from "react-hook-form";
import DemographicsDataChecks from "typedef/DemographicsDataChecks";

type InnerFormProps = UseFormReturn<DemographicsDataChecks, unknown>;

const SexOptions: FC<{ form: InnerFormProps }> = ({ form }) => {
  const theme = useTheme();

  const handleCheckboxChange = useCallback(
    (field: keyof DemographicsDataChecks["sex"]) => () => {
      form.setValue(`sex.${field}`, !form.getValues(`sex.${field}`), {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [form],
  );

  return (
    <Fragment>
      <Box
        mt={2}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h5" color={theme.palette.text.primary}>
          Sex
        </Typography>
        <Typography variant="subtitle2" color={theme.palette.text.secondary}>
          Check one or more
        </Typography>
      </Box>
      <Box>
        <FormGroup>
          <FormControlLabel
            label="Female"
            control={
              <Checkbox
                checked={form.getValues().sex.female}
                onChange={handleCheckboxChange("female")}
              />
            }
          />
          <FormControlLabel
            label="Male"
            control={
              <Checkbox
                checked={form.getValues().sex.male}
                onChange={handleCheckboxChange("male")}
              />
            }
          />
          <FormControlLabel
            label="I do not wish to provide this Information"
            control={
              <Checkbox
                checked={form.getValues().sex.prefNoSay}
                onChange={handleCheckboxChange("prefNoSay")}
              />
            }
          />
        </FormGroup>
      </Box>
    </Fragment>
  );
};
export default SexOptions;
