const creditScoreOptions = [
  { label: "640 - 659", value: "640 - 659" },
  { label: "660 - 679", value: "660 - 679" },
  { label: "680 - 699", value: "680 - 699" },
  { label: "700 - 719", value: "700 - 719" },
  { label: "720 - 739", value: "720 - 739" },
  { label: "740 - 759", value: "740 - 759" },
  { label: "760 - 779", value: "760 - 779" },
  { label: "780 - 850", value: "780 - 850" },
];

export default creditScoreOptions;
