import { Box, Checkbox, Typography, useTheme } from "@mui/material";
import CButton from "components/CButton";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import { FC, useState } from "react";

interface Props {
  functionHandler: () => void;
  isLoading: boolean;
  isAccepting: boolean;
}

const AcceptOfferButton: FC<Props> = ({
  functionHandler,
  isLoading,
  isAccepting,
}) => {
  const theme = useTheme();
  const { privateLabel } = usePrivateLabel();

  const [isChecked, setIsChecked] = useState(false);

  const lenderName = privateLabel?.isNFTYDoor
    ? "Homebridge Financial Services, Inc."
    : privateLabel?.lenderName;

  return (
    <Box>
      <Box
        display={"flex"}
        flexDirection="row"
        justifyContent={{ xs: "center", md: "left" }}
        alignItems="flex-start"
        mt="40px"
      >
        <Box fontFamily="Pro Display Semibold" ml="-6px">
          <Checkbox
            name="hardCredit"
            checked={isChecked}
            onClick={() => {
              setIsChecked(!isChecked);
            }}
          />
        </Box>
        <Typography
          mt={0.8}
          variant="subtitle1"
          color={theme.palette.text.primary}
          textAlign={"justify"}
        >
          By clicking <b>"Accept Offer & Start Income Verification"</b>, I am
          providing ‘written instructions’ to <b>{lenderName}</b> under the Fair
          Credit Reporting Act authorizing <b>{lenderName}</b> to obtain
          information from my personal credit profile or other information
          related to my employment and income information.
        </Typography>
      </Box>
      <Box mt={"40px"}>
        <CButton
          fullWidth
          type="submit"
          variant="contained"
          sx={{ borderRadius: "33px" }}
          loading={isAccepting}
          onClick={functionHandler}
          disabled={!isChecked || isLoading}
          name="acceptAndCompleteHELOCApplication"
        >
          Accept & Start Income Verification
        </CButton>
      </Box>
      <Box mt={4}>
        <Typography
          variant="subtitle2"
          textAlign={"justify"}
          color={theme.palette.text.secondary}
        >
          This letter is solely a pre-qualification for terms of a loan with{" "}
          {privateLabel?.lenderName}. NMLS #{privateLabel?.NMLScompany}, that
          you may be eligible for based on your stated information. This
          pre-qualification is not a commitment to lend or an approval of an
          application for credit with {privateLabel?.lenderName}. A written
          commitment to make a mortgage loan has not yet been issued. This is
          not a rate lock, and the APR, interest rate, and other related terms
          and conditions shown on this pre-qualification are sample terms only
          and are subject to change until you are approved for a loan and a
          commitment or loan offer is issued. In order for{" "}
          {privateLabel?.lenderName} to review your application for credit, you
          will need to submit a complete loan application and provide the
          information detailed above. Please note that the final terms and
          conditions of any loan offered to you will be based on{" "}
          {privateLabel?.lenderName} underwriting of and verification of the
          provided information. You are not required to obtain a loan from{" "}
          {privateLabel?.lenderName} just because you have received this
          pre-qualification.
        </Typography>
      </Box>
    </Box>
  );
};

export default AcceptOfferButton;
