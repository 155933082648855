import Loan from "typedef/Loan";
import useLoan from "./useLoan";
import React, { ReactNode, useContext } from "react";
import { PropertyType } from "typedef/Property";
import useDocuments, { DocumentsHook } from "./Documents/useDocuments";

export type HomeMonitorContextType = {
  loan?: Loan;
  property?: PropertyType;
  documentsHook?: DocumentsHook;
};

const useHomeMonitor = () => {
  return useContext(HomeMonitorContext);
};

export const HomeMonitorContext = React.createContext<HomeMonitorContextType>(
  {},
);

export const HomeMonitorContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { loan } = useLoan();
  const property = loan?.property;
  const documentsHook = useDocuments(loan);

  return (
    <HomeMonitorContext.Provider value={{ loan, property, documentsHook }}>
      {children}
    </HomeMonitorContext.Provider>
  );
};

export default useHomeMonitor;
